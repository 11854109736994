import { NavigationProps, WsaProps } from "../wsa/WsaViewModel";
import React from "react";
import { InteriorWaterPayload } from "../model/InteriorWaterPayload";
import { Box } from "@mui/material";
import { MARGIN_DEFAULT } from "../util/constant/constants";
import { isProvided, RequiredField } from "../ui/RequiredField";
import { Navigation } from "../ui/Navigation";
import { TriState } from "../model/TriState";
import { BinaryChoice, YesNo } from "../ui/BinaryChoice";
import { MultiDropdown } from "../ui/Dropdown";
import { simpleDropdownItem } from "../ui/dropdown/DropdownItem";
import { Label } from "../ui/Label";
import { FastTextInput } from "../ui/TextInput";
import { FeatureFlags } from "../util/constant/flags";

interface Props
  extends Pick<WsaProps, keyof InteriorWaterPayload | "onInteriorWaterSubmit">,
    NavigationProps {}

interface State extends InteriorWaterPayload {
  showErrors: boolean;
}

const WATER_PIPING_TYPES = [
  simpleDropdownItem(`Copper`),
  simpleDropdownItem(`PVC`),
  simpleDropdownItem(`PEX - A (Expansion)`),
  simpleDropdownItem(`PEX - B`),
  simpleDropdownItem(`CPVC`),
  simpleDropdownItem(`Galvinized`),
  simpleDropdownItem(`Polybutylene`),
  simpleDropdownItem(`Other`),
];

const WATER_SOURCE_TRUE = "City";
const WATER_SOURCE_FALSE = "Well";

export class InteriorWaterSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { onInteriorWaterSubmit, onPreviousSection, ...rest } = props;
    this.state = {
      ...rest,
      showErrors: false,
    };
  }

  handleWaterSource = (value: TriState) => {
    this.setState({
      interiorWaterSource:
        value === TriState.TRUE
          ? WATER_SOURCE_TRUE
          : value === TriState.FALSE
          ? WATER_SOURCE_FALSE
          : "",
    });
  };

  handleWaterPiping = (value: string[]) => {
    this.setState({ interiorMainWaterPipingType: value });
  };

  handleVisiblePipesAtRisk = (value: TriState) => {
    this.setState({ interiorVisiblePipesRiskOfFreezing: value });
  };

  handleRecommendations = (value: string) => {
    this.setState({ interiorRecommendations: value });
  };

  canSubmit = () => {
    if (FeatureFlags.DONT_ENFORCE_REQUIRED) {
      return true;
    }

    const { interiorWaterSource, interiorMainWaterPipingType } = this.state;
    if (!isProvided(interiorMainWaterPipingType)) {
      return false;
    }

    return isProvided(interiorWaterSource);
  };

  handleSubmit = () => {
    if (this.canSubmit()) {
      const { showErrors, ...rest } = this.state;
      const { onInteriorWaterSubmit } = this.props;
      onInteriorWaterSubmit(rest);
    } else {
      this.setState({ showErrors: true });
    }
  };

  render() {
    const { onPreviousSection } = this.props;
    const {
      showErrors,
      interiorMainWaterPipingType,
      interiorWaterSource,
      interiorRecommendations,
      interiorVisiblePipesRiskOfFreezing,
    } = this.state;
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Water Source" />
          <BinaryChoice
            yes={WATER_SOURCE_TRUE}
            no={WATER_SOURCE_FALSE}
            value={
              interiorWaterSource === WATER_SOURCE_TRUE
                ? TriState.TRUE
                : interiorWaterSource === WATER_SOURCE_FALSE
                ? TriState.FALSE
                : TriState.UNKNOWN
            }
            onChange={this.handleWaterSource}
          />
          <RequiredField show={showErrors} value={interiorWaterSource} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <MultiDropdown
            label="Main Water Piping Type (Interior)"
            value={interiorMainWaterPipingType}
            onChange={this.handleWaterPiping}
            items={WATER_PIPING_TYPES}
          />
          <RequiredField
            show={showErrors}
            value={interiorMainWaterPipingType}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Visible Pipes at Risk of Freezing" />
          <YesNo
            value={interiorVisiblePipesRiskOfFreezing}
            onChange={this.handleVisiblePipesAtRisk}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Water Pipe Recommendations?"
            value={interiorRecommendations}
            onChange={this.handleRecommendations}
            multiline={true}
            maxRows={6}
          />
        </Box>
        <Box display="flex" mb={MARGIN_DEFAULT}>
          <Navigation
            onNext={this.handleSubmit}
            onPrevious={onPreviousSection}
          />
        </Box>
      </Box>
    );
  }
}
