import { Attachment } from "../../model/Attachment";
import React, { FormEvent } from "react";

interface ThumbProps {
  attachment: Attachment;
  onClick: (attachment: Attachment) => void;
}

export class Thumbnail extends React.Component<ThumbProps> {
  handleClick = ($event: FormEvent<any>) => {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    const { onClick, attachment } = this.props;
    onClick(attachment);
  };

  render() {
    const { attachment } = this.props;
    return attachment.isDataObjectUrl ? (
      <img
        width="100%"
        height="100%"
        src={attachment.data}
        alt={attachment.name}
        onClick={this.handleClick}
      />
    ) : (
      <video
        controls={false}
        muted={true}
        loop={false}
        autoPlay={false}
        src={attachment.data}
        width="100%"
        height="100%"
      />
    );
  }
}

