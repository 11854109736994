import { Box, Typography } from "@mui/material";
import PlusIcon from "@mui/icons-material/Add";
import React from "react";

const DASHED_BORDER_STYLE: object = {};

interface Props {
  onClick: () => void;
}

export function UploadZone(props: Props) {
  const { onClick } = props;
  return (
    <Box
      className="cursor-pointer"
      sx={DASHED_BORDER_STYLE}
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      onClick={onClick}
    >
      <Box mx="auto" mt="auto">
        <PlusIcon component="svg" color="primary" fontSize="large" />
      </Box>
      <Box mx="auto" mb="auto">
        <Typography component="div" variant="caption" color="primary">
          Click to Upload
        </Typography>
      </Box>
    </Box>
  );
}
