import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { generateRandomId } from "../util/ext/id";
import { DropdownItem } from "./dropdown/DropdownItem";

interface CommonProps {
  label: string;
  items: DropdownItem[];
}

interface InternalProps extends CommonProps {
  value: string | string[];
  onChange: (value: string | string[]) => void;
  multiple: boolean;
}

interface Props extends CommonProps {
  value: string;
  onChange: (value: string) => void;
}

interface MultiProps extends CommonProps {
  value: string[];
  onChange: (values: string[]) => void;
}

interface State {
  id: string;
}

export class Dropdown extends React.Component<Props> {
  handleChange = (value: string | string[]) => {
    const { onChange } = this.props;
    onChange(Array.isArray(value) ? value.join(",") : value);
  };

  render() {
    const { onChange, ...rest } = this.props;
    return (
      <InternalDropdown
        {...rest}
        onChange={this.handleChange}
        multiple={false}
      />
    );
  }
}

export class MultiDropdown extends React.Component<MultiProps> {
  handleChange = (value: string | string[]) => {
    const { onChange } = this.props;
    onChange(Array.isArray(value) ? value : value.split(","));
  };

  render() {
    const { onChange, ...rest } = this.props;
    return (
      <InternalDropdown
        {...rest}
        onChange={this.handleChange}
        multiple={true}
      />
    );
  }
}

class InternalDropdown extends React.Component<InternalProps, State> {
  constructor(props: InternalProps) {
    super(props);
    this.state = {
      id: generateRandomId(),
    };
  }

  handleChange = (event: SelectChangeEvent<string | string[]>) => {
    const { onChange } = this.props;
    const value: string | string[] = event.target.value;
    onChange(typeof value === "string" ? value.split(",") : value);
  };

  render() {
    const { id } = this.state;
    const { value, label, items, multiple } = this.props;
    const labelId = `${id}-label`;
    return (
      <FormControl fullWidth={true}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          labelId={labelId}
          id={id}
          multiple={multiple}
          value={value}
          label={label}
          onChange={this.handleChange}
        >
          {items.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}
