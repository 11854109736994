import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import { Wsa } from "./wsa/Wsa";
import reportWebVitals from "./reportWebVitals";
import { Http } from "./http/client/Http";
import { FetchHttpClient } from "./http/impl/FetchHttpClient";
import { APP_NAME, APP_VERSION } from "./util/constant/constants";
import { RealWsaInteractor } from "./wsa/data/RealWsaInteractor";
import { WsaInteractorContext } from "./context/WsaInteractorContext";

/**
 * Initialize analytics
 */
function initNetworking() {
  // Plant the logger first so that we can get logs

  Http.setClient(new FetchHttpClient());
}

function renderReact() {
  const root = document.getElementById("root");
  if (!root) {
    throw new Error("Could not find document #root");
  }
  root.className = `${APP_NAME}-${APP_VERSION}`;
  root.style.overflowX = "hidden";

  const wsaInteractor = new RealWsaInteractor();

  ReactDOM.render(
    <React.StrictMode>
      <WsaInteractorContext.Provider value={wsaInteractor}>
        <Wsa />
      </WsaInteractorContext.Provider>
    </React.StrictMode>,
    root
  );
}

/**
 * Main function
 */
function main() {
  // Get current insurance type from page
  initNetworking();

  // Render the page
  renderReact();

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

main();
