import { ViewModel, ViewModelProps } from "../arch/ViewModel";
import React from "react";
import { Logger } from "../util/logger/Logger";
import { Section } from "./model/WsaSection";
import { UserInfoPayload } from "../model/UserInfoPayload";
import { AddressInfoPayload } from "../model/AddressInfoPayload";
import { WaterPressurePayload } from "../model/WaterPressurePayload";
import { TriState } from "../model/TriState";
import { InteriorWaterPayload } from "../model/InteriorWaterPayload";
import { SinkPayload } from "../model/SinkPayload";
import { ToiletPayload } from "../model/ToiletPayload";
import { WashingMachinePayload } from "../model/WashingMachinePayload";
import { OtherWaterPayload } from "../model/OtherWaterPayload";
import { WaterTreatmentPayload } from "../model/WaterTreatmentPayload";
import { SewagePayload } from "../model/SewagePayload";
import { ExteriorPayload } from "../model/ExteriorPayload";
import { SmartValvePayload } from "../model/SmartValvePayload";
import { WsaInteractor } from "./data/WsaInteractor";
import { WsaPayload } from "./model/WsaPayload";
import { SubmitState } from "./model/SubmitState";

interface Props extends ViewModelProps<WsaProps> {
  interactor: WsaInteractor;
}

interface Common extends WsaPayload, SubmitState {
  // Screen
  section: Section;
}

interface State extends Common {}

export interface NavigationProps {
  onPreviousSection: () => void;
}

export interface WsaProps extends Common, NavigationProps {
  onUserInfoSubmit: (data: UserInfoPayload) => void;
  onAddressInfoSubmit: (data: AddressInfoPayload) => void;
  onWaterPressureSubmit: (data: WaterPressurePayload) => void;
  onInteriorWaterSubmit: (data: InteriorWaterPayload) => void;
  onSinkSubmit: (data: SinkPayload) => void;
  onToiletSubmit: (data: ToiletPayload) => void;
  onWashingMachineSubmit: (data: WashingMachinePayload) => void;
  onOtherWaterSubmit: (data: OtherWaterPayload) => void;
  onWaterTreatmentSubmit: (data: WaterTreatmentPayload) => void;
  onSewageSubmit: (data: SewagePayload) => void;
  onExteriorSubmit: (data: ExteriorPayload) => void;
  onSmartValveSubmit: (data: SmartValvePayload) => void;

  // Submission
  onResetSession: () => void;
  onCloseError: () => void;
}

const ViewModelContext = React.createContext<WsaProps | undefined>(undefined);

function initialState(): State {
  return {
    // Submission
    submitting: false,
    submitError: undefined,
    submitComplete: false,

    // Screen
    section: Section.CUSTOMER,

    // User
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",

    // Address
    address1: "",
    address2: "",
    city: "",
    zipCode: "",

    // Water Pressure
    waterPressureGaugePictures: [],
    pressureReducingValveSize: "",
    pressureReducingValveWorkingCondition: TriState.UNKNOWN,
    pressureReducingRecommendations: "",
    mainShutoffValveSize: "",
    mainShutoffValveWorkingCondition: TriState.UNKNOWN,
    mainShutoffRecommendations: "",
    waterHeaterType: "",
    waterHeaterExpansionTank: TriState.UNKNOWN,
    waterHeaterExpansionTankTested: TriState.UNKNOWN,
    waterHeaterGallons: "",
    waterHeaterRatingPlatePictures: [],
    waterHeaterRecommendations: "",
    waterHeaterTankLocationPictures: [],
    circulationSystemPresent: TriState.UNKNOWN,
    dischargePipingAcceptable: TriState.UNKNOWN,
    panAndDrainPresent: TriState.UNKNOWN,

    // Interior Water
    interiorMainWaterPipingType: [],
    interiorVisiblePipesRiskOfFreezing: TriState.UNKNOWN,
    interiorWaterSource: "",
    interiorRecommendations: "",

    // Sink
    sinkRecommendations: "",
    sinkShutoffValve: TriState.UNKNOWN,
    sinkSupplyLine: "",

    // Toilet
    toiletDyeTest: TriState.UNKNOWN,
    toiletRecommendations: "",
    toiletSeal: TriState.UNKNOWN,
    toiletShutoffCount: "",
    toiletShutoffValve: TriState.UNKNOWN,
    toiletSupplyLine: "",
    toiletWaterFillDevice: TriState.UNKNOWN,
    toiletWaterFillDeviceCount: "",

    // Washing Machine
    washingMachineRecommendations: "",
    washingMachineSupplyLineCount: "",
    washingMachineShutoffValve: TriState.UNKNOWN,
    washingMachineSupplyLine: "",

    // Other Water
    dishwasherSupplyLine: "",
    iceMakerSupplyLine: "",
    otherWaterRecommendations: "",

    // Water Treatment
    treatmentRecommendations: "",
    treatmentSystems: "",
    treatmentTypeAndLeaks: "",

    // Sewage
    sewageDischargePiping: "",
    sewagePitAndPump: "",
    sewageTank: TriState.UNKNOWN,
    sewageRecommendations: "",

    // Exterior
    exteriorFaucets: "",
    exteriorLinesAndProtection: "",
    exteriorRecommendations: "",

    // Smart valve
    smartValvePrvReplace: TriState.UNKNOWN,
    smartValveSpecialTools: "",
    smartValveUtilitiesMarked: TriState.UNKNOWN,
    smartValveWaterLineDepth: "",
    smartValveRecommendations: "",
    smartValveLocation: "",
    smartValveInstallationType: "",
    smartValveOutletWithinReach: TriState.UNKNOWN,
    smartValveElectricianNeeded: TriState.UNKNOWN,
    smartValveWifiSignal: TriState.UNKNOWN,
    smartValveMainPipeSize: "",
    smartValveMainPipeMaterial: "",
    smartValveDigging: TriState.UNKNOWN,
  };
}

export class WsaViewModel extends ViewModel<Props, State, WsaProps> {
  constructor(props: Props) {
    super(props);
    this.state = initialState();
  }

  handleComputePreviousSection = () => {
    const { section } = this.state;
    switch (section) {
      case Section.ADDRESS:
        return Section.CUSTOMER;
      case Section.WATER_PRESSURE:
        return Section.ADDRESS;
      case Section.INTERIOR_WATER:
        return Section.WATER_PRESSURE;
      case Section.SINK:
        return Section.INTERIOR_WATER;
      case Section.TOILET:
        return Section.SINK;
      case Section.WASHING_MACHINE:
        return Section.TOILET;
      case Section.OTHER_WATER:
        return Section.WASHING_MACHINE;
      case Section.WATER_TREATMENT:
        return Section.OTHER_WATER;
      case Section.SEWAGE:
        return Section.WATER_TREATMENT;
      case Section.EXTERIOR:
        return Section.SEWAGE;
      case Section.SMART_VALVE:
        return Section.EXTERIOR;
      default:
        return null;
    }
  };

  handleComputeNextSection = () => {
    const { section } = this.state;
    switch (section) {
      case Section.CUSTOMER:
        return Section.ADDRESS;
      case Section.ADDRESS:
        return Section.WATER_PRESSURE;
      case Section.WATER_PRESSURE:
        return Section.INTERIOR_WATER;
      case Section.INTERIOR_WATER:
        return Section.SINK;
      case Section.SINK:
        return Section.TOILET;
      case Section.TOILET:
        return Section.WASHING_MACHINE;
      case Section.WASHING_MACHINE:
        return Section.OTHER_WATER;
      case Section.OTHER_WATER:
        return Section.WATER_TREATMENT;
      case Section.WATER_TREATMENT:
        return Section.SEWAGE;
      case Section.SEWAGE:
        return Section.EXTERIOR;
      case Section.EXTERIOR:
        return Section.SMART_VALVE;
      default:
        return null;
    }
  };

  handleUserInfoSubmit = (data: UserInfoPayload) => {
    this.setState(data, this.handleNextSection);
  };

  handleAddressInfoSubmit = (data: AddressInfoPayload) => {
    this.setState(data, this.handleNextSection);
  };

  handleWaterPressureSubmit = (data: WaterPressurePayload) => {
    this.setState(data, this.handleNextSection);
  };

  handleInteriorWaterSubmit = (data: InteriorWaterPayload) => {
    this.setState(data, this.handleNextSection);
  };

  handleSinkSubmit = (data: SinkPayload) => {
    this.setState(data, this.handleNextSection);
  };

  handleToiletSubmit = (data: ToiletPayload) => {
    this.setState(data, this.handleNextSection);
  };

  handleWashingMachineSubmit = (data: WashingMachinePayload) => {
    this.setState(data, this.handleNextSection);
  };

  handleOtherWaterSubmit = (data: OtherWaterPayload) => {
    this.setState(data, this.handleNextSection);
  };

  handleTreatmentSubmit = (data: WaterTreatmentPayload) => {
    this.setState(data, this.handleNextSection);
  };

  handleSewageSubmit = (data: SewagePayload) => {
    this.setState(data, this.handleNextSection);
  };

  handleExteriorSubmit = (data: ExteriorPayload) => {
    this.setState(data, this.handleNextSection);
  };

  handleSmartValveSubmit = (data: SmartValvePayload) => {
    this.setState(data, this.handleNextSection);
  };

  handleSubmitWsa = () => {
    const { interactor } = this.props;
    const { submitting, submitError, section, ...rest } = this.state;
    if (submitting) {
      return;
    }

    Logger.log("Attempt WSA submission: ", rest);
    this.setState({ submitting: true }, () => {
      interactor
        .submit(rest)
        .then(() => {
          Logger.log("WSA Submitted to server!!!");
          this.setState({ submitError: undefined, submitComplete: true });
        })
        .catch((e) => {
          Logger.error(e, "Error submitting WSA report");
          this.setState({ submitError: e, submitComplete: false });
        })
        .finally(() => {
          this.setState({ submitting: false });
        });
    });
  };

  handleResetSession = () => {
    Logger.log("Reset session back to initial state");
    this.setState(initialState());
  };

  handleCloseError = () => {
    this.setState({ submitError: undefined });
  };

  handleNextSection = () => {
    const section = this.handleComputeNextSection();
    if (section) {
      this.setState({ section });
    } else {
      Logger.log("Final section next button clicked");
      this.handleSubmitWsa();
    }
  };

  handlePreviousSection = () => {
    const section = this.handleComputePreviousSection();
    if (section) {
      this.setState({ section });
    } else {
      Logger.log("First section previous button clicked");
    }
  };

  protected viewModelContext = ViewModelContext;

  protected produceExportProps(props: Props, state: State): WsaProps {
    return {
      ...state,
      onPreviousSection: this.handlePreviousSection,
      onUserInfoSubmit: this.handleUserInfoSubmit,
      onAddressInfoSubmit: this.handleAddressInfoSubmit,
      onWaterPressureSubmit: this.handleWaterPressureSubmit,
      onInteriorWaterSubmit: this.handleInteriorWaterSubmit,
      onSinkSubmit: this.handleSinkSubmit,
      onToiletSubmit: this.handleToiletSubmit,
      onWashingMachineSubmit: this.handleWashingMachineSubmit,
      onOtherWaterSubmit: this.handleOtherWaterSubmit,
      onWaterTreatmentSubmit: this.handleTreatmentSubmit,
      onSewageSubmit: this.handleSewageSubmit,
      onExteriorSubmit: this.handleExteriorSubmit,
      onSmartValveSubmit: this.handleSmartValveSubmit,

      // Submission
      onResetSession: this.handleResetSession,
      onCloseError: this.handleCloseError,
    };
  }
}

/**
 * Custom hook for retrieving WsaViewModel state
 */
export function useWsa(): WsaProps {
  const exportProps = React.useContext(ViewModelContext);
  if (!exportProps) {
    throw new Error("This Component must be used in a WsaViewModel");
  }
  return exportProps;
}
