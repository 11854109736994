/**
 * Debounced function
 */
export type DebouncedFunction = { (...args: any[]): void; cancel: () => void };

/**
 * Debounce from lodash
 *
 * @param func
 * @param delay
 * @param options
 * @return Function
 */
export function debounce(
  func: Function,
  delay: number,
  options: { leading?: boolean } = { leading: false }
): DebouncedFunction {
  let timerId: number | undefined;

  const { leading } = options;
  const result = function debounced(...args: any[]) {
    if (!timerId && leading) {
      func(...args);
    }
    window.clearTimeout(timerId);

    timerId = window.setTimeout(() => func(...args), delay);
  };

  result.cancel = function cancel() {
    window.clearTimeout(timerId);
    timerId = undefined;
  };

  return result;
}
