import { NavigationProps, WsaProps } from "../wsa/WsaViewModel";
import React from "react";
import { Box } from "@mui/material";
import { MARGIN_DEFAULT } from "../util/constant/constants";
import { isProvided, RequiredField } from "../ui/RequiredField";
import { Navigation } from "../ui/Navigation";
import { OperationalStatus } from "../ui/BinaryChoice";
import { Dropdown } from "../ui/Dropdown";
import { simpleDropdownItem } from "../ui/dropdown/DropdownItem";
import { Label } from "../ui/Label";
import {
  FastTextInput,
  MUTATE_NUMBER_ONLY,
  NUMBER_INPUT,
} from "../ui/TextInput";
import { WashingMachinePayload } from "../model/WashingMachinePayload";
import { TriState } from "../model/TriState";
import { OptionalSection } from "../ui/OptionalSection";
import { stringIsNotBlank } from "../util/ext/string";
import { FeatureFlags } from "../util/constant/flags";

interface Props
  extends Pick<
      WsaProps,
      keyof WashingMachinePayload | "onWashingMachineSubmit"
    >,
    NavigationProps {}

interface State extends WashingMachinePayload {
  showErrors: boolean;
}

const SUPPLY_LINES = [
  simpleDropdownItem(`Plastic`),
  simpleDropdownItem(`Metal`),
  simpleDropdownItem(`Stainless Braided`),
  simpleDropdownItem(`Other`),
];

export class WashingMachineSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { onWashingMachineSubmit, onPreviousSection, ...rest } = props;
    this.state = {
      ...rest,
      showErrors: false,
    };
  }

  handleSupplyLines = (value: string) => {
    this.setState({ washingMachineSupplyLine: value });
  };

  handleSupplyLinesCount = (value: string) => {
    this.setState({ washingMachineSupplyLineCount: value });
  };

  handleShutoffValves = (value: TriState) => {
    this.setState({ washingMachineShutoffValve: value });
  };

  handleRecommendations = (value: string) => {
    this.setState({ washingMachineRecommendations: value });
  };

  isShowWashingMachineCount = () => {
    const { washingMachineSupplyLine } = this.state;
    return stringIsNotBlank(washingMachineSupplyLine);
  };

  canSubmit = () => {
    if (FeatureFlags.DONT_ENFORCE_REQUIRED) {
      return true;
    }

    const { washingMachineSupplyLineCount } = this.state;
    if (this.isShowWashingMachineCount()) {
      if (!isProvided(washingMachineSupplyLineCount)) {
        return false;
      }
    }

    return true;
  };

  handleSubmit = () => {
    if (this.canSubmit()) {
      const { showErrors, ...rest } = this.state;
      const { onWashingMachineSubmit } = this.props;
      onWashingMachineSubmit(rest);
    } else {
      this.setState({ showErrors: true });
    }
  };

  render() {
    const { onPreviousSection } = this.props;
    const {
      showErrors,
      washingMachineSupplyLine,
      washingMachineRecommendations,
      washingMachineSupplyLineCount,
      washingMachineShutoffValve,
    } = this.state;
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Dropdown
            label="Washing Machine Water Supply Lines"
            value={washingMachineSupplyLine}
            onChange={this.handleSupplyLines}
            items={SUPPLY_LINES}
          />
        </Box>
        <OptionalSection show={this.isShowWashingMachineCount()}>
          <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
            <FastTextInput
              label="Washing Machine Water Supply Lines (Quantity)"
              value={washingMachineSupplyLineCount}
              onChange={this.handleSupplyLinesCount}
              type="tel"
              inputProps={NUMBER_INPUT}
              mutate={MUTATE_NUMBER_ONLY}
            />
            <RequiredField
              show={showErrors}
              value={washingMachineSupplyLineCount}
            />
          </Box>
        </OptionalSection>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Washing Machine Shut off Valves" />
          <OperationalStatus
            value={washingMachineShutoffValve}
            onChange={this.handleShutoffValves}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Washing Machine Water Supply Recommendations?"
            value={washingMachineRecommendations}
            onChange={this.handleRecommendations}
            multiline={true}
            maxRows={6}
          />
        </Box>
        <Box display="flex" mb={MARGIN_DEFAULT}>
          <Navigation
            onNext={this.handleSubmit}
            onPrevious={onPreviousSection}
          />
        </Box>
      </Box>
    );
  }
}
