import { NavigationProps, WsaProps } from "../wsa/WsaViewModel";
import { Box } from "@mui/material";
import React from "react";
import { MARGIN_DEFAULT } from "../util/constant/constants";
import { Navigation } from "../ui/Navigation";
import { ProgressTextInput } from "../ui/ProgressTextInput";
import { AddressInfoPayload } from "../model/AddressInfoPayload";
import { isProvided, RequiredField } from "../ui/RequiredField";
import { FeatureFlags } from "../util/constant/flags";

interface Props
  extends Pick<WsaProps, keyof AddressInfoPayload | "onAddressInfoSubmit">,
    NavigationProps {}

interface State extends AddressInfoPayload {
  address2Ref?: HTMLInputElement;
  cityRef?: HTMLInputElement;
  zipCodeRef?: HTMLInputElement;

  showErrors: boolean;
}

export class AddressInfoSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { onAddressInfoSubmit, onPreviousSection, ...rest } = props;
    this.state = {
      ...rest,
      showErrors: false,
    };
  }

  setAddress2Ref = (ref?: HTMLInputElement) => {
    this.setState({ address2Ref: ref });
  };

  setCityRef = (ref?: HTMLInputElement) => {
    this.setState({ cityRef: ref });
  };

  setZipCodeRef = (ref?: HTMLInputElement) => {
    this.setState({ zipCodeRef: ref });
  };

  handleAddress1Updated = (value: string) => {
    this.setState({ address1: value });
  };

  handleAddress2Updated = (value: string) => {
    this.setState({ address2: value });
  };

  handleCityUpdated = (value: string) => {
    this.setState({ city: value });
  };

  handleZipUpdated = (value: string) => {
    this.setState({ zipCode: value });
  };

  canSubmit = () => {
    if (FeatureFlags.DONT_ENFORCE_REQUIRED) {
      return true;
    }

    const { address1, city, zipCode } = this.state;
    if (!isProvided(address1)) {
      return false;
    }

    if (!isProvided(city)) {
      return false;
    }

    return isProvided(zipCode);
  };

  handleSubmit = () => {
    if (this.canSubmit()) {
      const { showErrors, ...rest } = this.state;
      const { onAddressInfoSubmit } = this.props;
      onAddressInfoSubmit(rest);
    } else {
      this.setState({ showErrors: true });
    }
  };

  render() {
    const { onPreviousSection } = this.props;
    const { address1, address2, city, zipCode } = this.state;
    const { showErrors, address2Ref, cityRef, zipCodeRef } = this.state;
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <ProgressTextInput
            autoFocus={true}
            nextRef={address2Ref}
            value={address1}
            onChange={this.handleAddress1Updated}
            label="Street Address"
            fullWidth={true}
          />
          <RequiredField show={showErrors} value={address1} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <ProgressTextInput
            inputRef={this.setAddress2Ref}
            nextRef={cityRef}
            value={address2}
            onChange={this.handleAddress2Updated}
            label="Apartment/Unit/Suite"
            fullWidth={true}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <ProgressTextInput
            inputRef={this.setCityRef}
            nextRef={zipCodeRef}
            value={city}
            onChange={this.handleCityUpdated}
            label="City"
            fullWidth={true}
          />
          <RequiredField show={showErrors} value={city} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <ProgressTextInput
            inputRef={this.setZipCodeRef}
            onSubmit={this.handleSubmit}
            value={zipCode}
            onChange={this.handleZipUpdated}
            label="ZIP Code"
            fullWidth={true}
          />
          <RequiredField show={showErrors} value={zipCode} />
        </Box>
        <Box display="flex" mb={MARGIN_DEFAULT}>
          <Navigation
            onNext={this.handleSubmit}
            onPrevious={onPreviousSection}
          />
        </Box>
      </Box>
    );
  }
}
