import { HttpClient } from "../HttpClient";
import { HttpPayload } from "../HttpPayload";
import { Logger } from "../../util/logger/Logger";

class HttpClass implements HttpClient<any> {
  private client?: HttpClient<any>;

  setClient(client: HttpClient<any>) {
    this.client = client;
  }

  private getClient(): HttpClient<any> {
    const c = this.client;
    if (!c) {
      const msg = "Missing HTTP client";
      Logger.warn(msg);
      throw new Error(msg);
    }

    return c;
  }

  async delete(data: HttpPayload<any>): Promise<any> {
    return this.getClient().delete(data);
  }

  async get(data: HttpPayload<any>): Promise<any> {
    return this.getClient().get(data);
  }

  async patch(data: HttpPayload<any>): Promise<any> {
    return this.getClient().patch(data);
  }

  async post(data: HttpPayload<any>): Promise<any> {
    return this.getClient().post(data);
  }

  async put(data: HttpPayload<any>): Promise<any> {
    return this.getClient().put(data);
  }
}

export const Http = new HttpClass();
