import { TriState } from "../model/TriState";
import { Box, Typography } from "@mui/material";
import React from "react";
import { stringIsNotBlank } from "../util/ext/string";

type Value = string | TriState | undefined | Array<any>;

interface Props {
  show: boolean;
  value: Value;
}

export function isProvided(value: Value): boolean {
  if (value === undefined) {
    return false;
  }

  if (Array.isArray(value)) {
    return value.length > 0;
  }

  if (
    value === TriState.UNKNOWN ||
    value === TriState.TRUE ||
    value === TriState.FALSE
  ) {
    return value !== TriState.UNKNOWN;
  }

  return stringIsNotBlank(value);
}

function canShow(props: Props) {
  // const { show } = props;
  // if (!show) {
  //   return false;
  // }

  const { value } = props;
  return !isProvided(value);
}

export function RequiredField(props: Props) {
  const show = canShow(props);
  return (
    <Box visibility={show ? "visible" : "hidden"}>
      <Typography variant="caption" color="error">
        This field is required.
      </Typography>
    </Box>
  );
}
