import React, { ReactElement, ReactNode } from "react";

/**
 * Strictly enforce that a ViewModel renders one child
 */
export interface ViewModelProps<E> {
  children: ReactElement<E>;
}

/**
 * A ViewModel
 *
 * A ViewModel provides and manipulates [State] to it's children via published [ExportProps]
 * ViewModels should only be concerned with the application state and should never include display JSX or CSS or
 * anything related to the actual page at all.
 */
export abstract class ViewModel<
  P extends ViewModelProps<E>,
  S,
  E
> extends React.Component<P, S> {
  /**
   * Context to provide to children Views
   */
  protected abstract viewModelContext: React.Context<E | undefined>;

  /**
   * Generate export props for the current component
   */
  private generateProps(): E {
    return this.produceExportProps(this.props, this.state);
  }

  /**
   * Given a components current [Props] and [State], construct an [ExportProps] that will be passed down
   * into children for rendering
   *
   * @param props - Props
   * @param state - State
   */
  protected abstract produceExportProps(props: P, state: S): E;

  /**
   * Render the ViewModel
   *
   * This function should not be overridden by an implementing class
   * The ViewModel should never be aware of the UI it is rendering.
   * The Controller is responsible for orchestrating together the Views with the ViewModel
   */
  render(): ReactNode {
    const Context = this.viewModelContext;
    const { children } = this.props;

    return (
      <Context.Provider value={this.generateProps()}>
        {children}
      </Context.Provider>
    );
  }
}
