import { Http } from "./Http";
import { HttpPayload } from "../HttpPayload";
import { HttpClient } from "../HttpClient";

class BlobHttpClient implements HttpClient<Blob> {
  async delete(data: HttpPayload<Blob>): Promise<any> {
    return Http.delete({
      url: data.url,
      headers: data.headers,
      body: data.body,
    });
  }

  async get(data: HttpPayload<Blob>): Promise<any> {
    return Http.get({
      url: data.url,
      headers: data.headers,
      body: data.body,
    });
  }

  async patch(data: HttpPayload<Blob>): Promise<any> {
    return Http.patch({
      url: data.url,
      headers: data.headers,
      body: data.body,
    });
  }

  async post(data: HttpPayload<Blob>): Promise<any> {
    return Http.post({
      url: data.url,
      headers: data.headers,
      body: data.body,
    });
  }

  async put(data: HttpPayload<Blob>): Promise<any> {
    return Http.put({
      url: data.url,
      headers: data.headers,
      body: data.body,
    });
  }
}

export const BlobHttp = new BlobHttpClient();
