import React, { ReactElement, ReactNode } from "react";
import { Box } from "@mui/material";

const IMAGE_SIZE = 120;

interface ItemProps {
  children:
    | ReactElement<any, any>
    | ReactElement<any, any>[]
    | ((props: { isHovering: boolean }) => ReactNode);
}

interface ItemState {
  isHovering: boolean;
}

export class Item extends React.Component<ItemProps, ItemState> {
  constructor(props: ItemProps) {
    super(props);
    this.state = {
      isHovering: false,
    };
  }

  handleHovering = () => {
    this.setState({ isHovering: true });
  };

  handleNotHovering = () => {
    this.setState({ isHovering: false });
  };

  componentWillUnmount(): void {
    this.setState({ isHovering: false });
  }

  render() {
    const { children } = this.props;
    const { isHovering } = this.state;
    return (
      <Box
        display="inline-block"
        position="relative"
        height="100%"
        width={IMAGE_SIZE}
        maxWidth={IMAGE_SIZE}
        minWidth={IMAGE_SIZE}
        onMouseEnter={this.handleHovering}
        onMouseLeave={this.handleNotHovering}
      >
        {typeof children === "function" ? children({ isHovering }) : children}
      </Box>
    );
  }
}
