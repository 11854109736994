/**
 * Read a file and provide callbacks
 * @param file
 * @return Promise
 */
import { Logger } from "../logger/Logger";

export function readFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.addEventListener("load", (event: ProgressEvent<FileReader>) => {
      const { target } = event;
      if (!target) {
        const msg = "load event called with no target!";
        Logger.warn(msg, event);
        reject(new Error(msg));
        return;
      }

      const { result } = target;
      if (!result) {
        const msg = "load event called with no result!";
        Logger.warn(msg, event);
        reject(new Error(msg));
        return;
      }

      if (typeof result !== "string") {
        const msg = "expected string but got ArrayBuffer";
        Logger.warn(msg, event);
        reject(new Error(msg));
        return;
      }

      Logger.log("File finished reading:", result);
      resolve(result);
    });

    fr.addEventListener("error", (event) => {
      Logger.warn("Error reading file: ", event);
      reject("error reading file");
    });

    fr.readAsDataURL(file);
  });
}
