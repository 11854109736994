import { HttpClient } from "../HttpClient";
import { createNetworkError } from "../error/NetworkError";
import { NetworkErrorEventBus } from "../error/NetworkErrorEventBus";
import { HttpPayload } from "../HttpPayload";
import { Logger } from "../../util/logger/Logger";

/**
 * Support only a limited amount of the fetch supported
 */
type HttpBody = FormData | string;

function publishNetworkError(
  e: any,
  req: { url: string; init: object },
  res: Response
): never {
  const networkError = createNetworkError(e, e.message || e.msg, req, res);
  NetworkErrorEventBus.publish(networkError);
  throw networkError;
}

async function performFetch<T>(url: string, init: RequestInit): Promise<T> {
  return window.fetch(url, init).then((res) => {
    const req = { url, init };
    if (res.ok) {
      // We cannot check HTTP response headers since server sends back JSON under the header text/html
      // Extra allocation I guess, but we do this anyway
      const fallbackRes = res.clone();
      return res
        .json()
        .catch((e) => {
          Logger.log("Network Response", {
            request: req,
            response: e
          });
          Logger.warn("Could not parse Response.Body as json, fallback to blob");
          return fallbackRes.blob();
        })
        .then((r) => {
          Logger.log("Network Response", {
            request: req,
            response: r
          });
          return r;
        });
    } else {
      return res
        .json()
        .then((error) => {
          Logger.warn("Network Error", {
            request: req,
            response: error
          });
          publishNetworkError(error, req, res);
        })
        .catch((e) => {
          Logger.warn("Network Error", {
            request: req,
            response: e
          });
          Logger.warn("Unable to convert error body to json: ", e);
          publishNetworkError(e, req, res);
        });
    }
  });
}

function urlSearchParamsGuard(
  tbd: URLSearchParams | any
): tbd is URLSearchParams {
  return !!(tbd as URLSearchParams);
}

function parseUrlWithArguments(
  url: string,
  body: HttpBody | undefined
): string {
  if (!body) {
    return url;
  }

  if (!urlSearchParamsGuard(body)) {
    return url;
  }

  return `${url}?${body.toString()}`;
}

export class FetchHttpClient implements HttpClient<HttpBody> {
  async delete(data: HttpPayload<HttpBody>): Promise<any> {
    return performFetch(data.url, {
      method: "DELETE",
      headers: data.headers
    });
  }

  async get(data: HttpPayload<HttpBody>): Promise<any> {
    return performFetch(parseUrlWithArguments(data.url, data.body), {
      method: "GET",
      headers: data.headers
    });
  }

  async patch(data: HttpPayload<HttpBody>): Promise<any> {
    return performFetch(data.url, {
      method: "PATCH",
      headers: data.headers,
      body: data.body
    });
  }

  async post(data: HttpPayload<HttpBody>): Promise<any> {
    return performFetch(data.url, {
      method: "POST",
      headers: data.headers,
      body: data.body
    });
  }

  async put(data: HttpPayload<HttpBody>): Promise<any> {
    return performFetch(data.url, {
      method: "PUT",
      headers: data.headers,
      body: data.body
    });
  }
}
