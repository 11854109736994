import { WsaViewModel } from "./WsaViewModel";
import { WsaSheet } from "./ui/WsaSheet";
import React from "react";
import { useWsaInteractor } from "../context/WsaInteractorContext";

export function Wsa() {
  const interactor = useWsaInteractor();
  return (
    <WsaViewModel interactor={interactor}>
      <WsaSheet />
    </WsaViewModel>
  );
}
