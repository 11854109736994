import React, { FormEvent } from "react";
import { Attachment } from "../../model/Attachment";
import { generateRandomId } from "../../util/ext/id";
import { readFile } from "../../util/helper/FileReader";
import { Logger } from "../../util/logger/Logger";
import { FileRejection } from "react-dropzone";
import { Box, Stack, Typography } from "@mui/material";
import { MARGIN_DEFAULT } from "../../util/constant/constants";
import { FileDropzone } from "./FileDropzone";
import { InternalProps } from "./InternalProps";
import { Item } from "./Item";
import { Thumbnail } from "./Thumbnail";
import TrashIcon from "@mui/icons-material/Delete";
import { UploadZone } from "./UploadZone";
import { FeatureFlags } from "../../util/constant/flags";

const IMAGE_SIZE = 120;

const STACK_STYLE: object = {
  height: IMAGE_SIZE,
  overflowX: "auto",
  overflowY: "hidden",
  maxWidth: "100%",

  // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
  transform: "translateZ(0)",
};

export class FileZone extends React.Component<InternalProps> {
  /**
   * Delete an image from the upload list
   *
   * @param attachment
   */
  handleDeleteClicked = (attachment: Attachment) => {
    return ($event: FormEvent<unknown>) => {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }
      const { onAttachmentRemoved } = this.props;
      onAttachmentRemoved(attachment);
    };
  };

  /**
   * View an image in the upload list
   *
   * @param attachment
   */
  handleImageClicked = (attachment: Attachment) => {
    const { onAttachmentViewed } = this.props;
    onAttachmentViewed(attachment);
  };

  handleAccept = (files: File[]) => {
    const attachments: Attachment[] = [];
    const work = [];
    for (const file of files) {
      if (file.type.startsWith("image")) {
        attachments.push({
          tempId: generateRandomId(),
          name: file.name,
          data: URL.createObjectURL(file),
          isDataObjectUrl: true,
          file,
        });
      } else {
        work.push(
          readFile(file)
            .then((result) => {
              attachments.push({
                tempId: generateRandomId(),
                name: file.name,
                data: result,
                isDataObjectUrl: false,
                file,
              });
            })
            .catch((e) => {
              Logger.error(e, "Failed reading possible video file: ", file);

              // Fall back to dumb thumbnail
              attachments.push({
                tempId: generateRandomId(),
                name: file.name,
                data: URL.createObjectURL(file),
                isDataObjectUrl: true,
                file,
              });
            })
        );
      }

      if (FeatureFlags.SINGLE_PICTURE_UPLOAD) {
        Logger.log("Report is limited to only a single picture");
        break;
      }
    }

    // Once all the work and otherwise is complete
    Promise.all(work).then(() => {
      const { onAttachmentAdded } = this.props;
      onAttachmentAdded(attachments);
    });
  };

  handleReject = (files: FileRejection[]) => {
    // TODO rejection
  };

  render() {
    const { attachments } = this.props;
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        borderColor="primary.main"
        p={MARGIN_DEFAULT / 2}
        border={1}
      >
        <Box mb={MARGIN_DEFAULT / 2} mx="auto">
          <Typography variant="caption" component="div" color="primary">
            You can also drag and drop your files here to upload
          </Typography>
        </Box>
        <FileDropzone
          onAccepted={this.handleAccept}
          onRejected={this.handleReject}
        >
          {({ open }) => (
            <Stack direction="row" spacing={1} sx={STACK_STYLE}>
              {attachments.map((a, i) => (
                <Item key={i}>
                  {({ isHovering }) => (
                    <React.Fragment>
                      <Box
                        position="absolute"
                        top={0}
                        bottom={0}
                        left={0}
                        right={0}
                      >
                        <Thumbnail
                          attachment={a}
                          onClick={this.handleImageClicked}
                        />
                      </Box>

                      {isHovering && (
                        <Box
                          position="absolute"
                          top={0}
                          bottom={0}
                          left={0}
                          right={0}
                          zIndex={10}
                          borderRadius={4}
                          bgcolor="#2D2D2D66"
                        />
                      )}
                      <Box
                        top={0}
                        right={0}
                        position="absolute"
                        overflow="hidden"
                        className="cursor-pointer"
                        zIndex={15}
                        borderRadius={4}
                        pt={MARGIN_DEFAULT / 2}
                        pr={MARGIN_DEFAULT / 2}
                        onClick={this.handleDeleteClicked(a)}
                      >
                        <TrashIcon component="svg" color="error" />
                      </Box>
                    </React.Fragment>
                  )}
                </Item>
              ))}

              {FeatureFlags.SINGLE_PICTURE_UPLOAD ? (
                attachments.length >= 1 ? null : (
                  <Item>
                    <UploadZone onClick={open} />
                  </Item>
                )
              ) : (
                <Item>
                  <UploadZone onClick={open} />
                </Item>
              )}
            </Stack>
          )}
        </FileDropzone>
      </Box>
    );
  }
}
