import { WsaInteractor } from "../wsa/data/WsaInteractor";
import React from "react";

/**
 * Context that provides the current singleton
 */
export const WsaInteractorContext = React.createContext<
  WsaInteractor | undefined
>(undefined);

/**
 * Custom hook for retrieving WsaInteractor
 */
export function useWsaInteractor(): WsaInteractor {
  const interactor = React.useContext(WsaInteractorContext);
  if (!interactor) {
    throw new Error("Missing WsaInteractorContext.Provider");
  }
  return interactor;
}
