import { NavigationProps, WsaProps } from "../wsa/WsaViewModel";
import { Box } from "@mui/material";
import React from "react";
import { MARGIN_DEFAULT } from "../util/constant/constants";
import { Navigation } from "../ui/Navigation";
import { ProgressTextInput } from "../ui/ProgressTextInput";
import { UserInfoPayload } from "../model/UserInfoPayload";
import { isProvided, RequiredField } from "../ui/RequiredField";
import { FeatureFlags } from "../util/constant/flags";

interface Props
  extends Pick<WsaProps, keyof UserInfoPayload | "onUserInfoSubmit">,
    NavigationProps {}

interface State extends UserInfoPayload {
  lastNameRef?: HTMLInputElement;
  emailRef?: HTMLInputElement;
  phoneRef?: HTMLInputElement;

  showErrors: boolean;
}

export class UserInfoSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { onUserInfoSubmit, onPreviousSection, ...rest } = props;
    this.state = {
      ...rest,
      showErrors: false,
    };
  }

  setLastNameRef = (ref?: HTMLInputElement) => {
    this.setState({ lastNameRef: ref });
  };

  setEmailRef = (ref?: HTMLInputElement) => {
    this.setState({ emailRef: ref });
  };

  setPhoneRef = (ref?: HTMLInputElement) => {
    this.setState({ phoneRef: ref });
  };

  handleFirstNameUpdated = (value: string) => {
    this.setState({ firstName: value });
  };

  handleLastNameUpdated = (value: string) => {
    this.setState({ lastName: value });
  };

  handleEmailUpdated = (value: string) => {
    this.setState({ email: value });
  };

  handlePhoneNumberUpdated = (value: string) => {
    this.setState({ phoneNumber: value });
  };

  canSubmit = () => {
    if (FeatureFlags.DONT_ENFORCE_REQUIRED) {
      return true;
    }

    const { firstName, lastName, email, phoneNumber } = this.state;
    if (!isProvided(firstName)) {
      return false;
    }

    if (!isProvided(lastName)) {
      return false;
    }

    if (!isProvided(email)) {
      return false;
    }

    return isProvided(phoneNumber);
  };

  handleSubmit = () => {
    if (this.canSubmit()) {
      const { showErrors, ...rest } = this.state;
      const { onUserInfoSubmit } = this.props;
      onUserInfoSubmit(rest);
    } else {
      this.setState({ showErrors: true });
    }
  };

  render() {
    const { onPreviousSection } = this.props;
    const { firstName, lastName, email, phoneNumber } = this.state;
    const { showErrors, lastNameRef, emailRef, phoneRef } = this.state;
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <ProgressTextInput
            autoFocus={true}
            nextRef={lastNameRef}
            value={firstName}
            onChange={this.handleFirstNameUpdated}
            label="First Name"
            fullWidth={true}
          />
          <RequiredField show={showErrors} value={firstName} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <ProgressTextInput
            inputRef={this.setLastNameRef}
            nextRef={emailRef}
            value={lastName}
            onChange={this.handleLastNameUpdated}
            label="Last Name"
            fullWidth={true}
          />
          <RequiredField show={showErrors} value={lastName} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <ProgressTextInput
            inputRef={this.setEmailRef}
            nextRef={phoneRef}
            value={email}
            onChange={this.handleEmailUpdated}
            label="Email"
            fullWidth={true}
          />
          <RequiredField show={showErrors} value={email} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <ProgressTextInput
            inputRef={this.setPhoneRef}
            value={phoneNumber}
            onChange={this.handlePhoneNumberUpdated}
            label="Phone Number"
            fullWidth={true}
            onSubmit={this.handleSubmit}
          />
          <RequiredField show={showErrors} value={phoneNumber} />
        </Box>
        <Box display="flex" mb={MARGIN_DEFAULT}>
          <Navigation
            isFirstScreen={true}
            onNext={this.handleSubmit}
            onPrevious={onPreviousSection}
          />
        </Box>
      </Box>
    );
  }
}
