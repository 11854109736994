/**
 * Regex for checking email address format
 * Copied from: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
 * Which was copied from Chromium
 */
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Get the length of the string's content, not including whitespace
 * @param s
 */
export function stringContentLength(s: string): number {
  return s.trim().length;
}

/**
 * A blank string is one comprised of only whitespace
 * @param s
 */
export function stringIsBlank(s: string): boolean {
  return stringContentLength(s) <= 0;
}

/**
 * A blank string is one comprised of only whitespace
 * @param s
 */
export function stringIsNotBlank(s: string): boolean {
  return !stringIsBlank(s);
}

/**
 * Tests whether this string looks like an email address
 * @param s
 */
export function stringIsEmail(s: string): boolean {
  return stringIsNotBlank(s) && EMAIL_REGEX.test(s);
}
