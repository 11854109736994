import packageJson from "../../../package.json";

export const APP_NAME = "Water Security Assessment";
export const APP_VERSION = packageJson.version;

export const INTERACTION_DEBOUNCE_TIME = 30;

export const MARGIN_DEFAULT = 2;

export const IS_DEBUG_MODE = process.env.NODE_ENV !== "production";
