export enum Section {
  CUSTOMER = "CUSTOMER",
  ADDRESS = "ADDRESS",
  WATER_PRESSURE = "WATER_PRESSURE",
  INTERIOR_WATER = "INTERIOR_WATER",
  SINK = "SINK",
  TOILET = "TOILET",
  WASHING_MACHINE = "WASHING_MACHINE",
  OTHER_WATER = "OTHER_WATER",
  WATER_TREATMENT = "WATER_TREATMENT",
  SEWAGE = "SEWAGE",
  EXTERIOR = "EXTERIOR",
  SMART_VALVE = "SMART_VALVE",
}
