import { Http } from "./Http";
import { HttpPayload } from "../HttpPayload";
import { KeyedObject } from "../../type/KeyedObject";
import { HttpClient } from "../HttpClient";

function wrapBody(body: KeyedObject<any> | undefined): KeyedObject<any> {
  if (!body) {
    body = {};
  }

  // Add any custom fields to body
  return body;
}

class JsonHttpClient implements HttpClient<KeyedObject<any>> {
  async delete(data: HttpPayload<KeyedObject<any>>): Promise<any> {
    return Http.delete({
      url: data.url,
      headers: data.headers,
      body: JSON.stringify(wrapBody(data.body))
    });
  }

  async get(data: HttpPayload<KeyedObject<any>>): Promise<any> {
    return Http.get({
      url: data.url,
      headers: data.headers,
      body: JSON.stringify(wrapBody(data.body))
    });
  }

  async patch(data: HttpPayload<KeyedObject<any>>): Promise<any> {
    return Http.patch({
      url: data.url,
      headers: data.headers,
      body: JSON.stringify(wrapBody(data.body))
    });
  }

  async post(data: HttpPayload<KeyedObject<any>>): Promise<any> {
    return Http.post({
      url: data.url,
      headers: data.headers,
      body: JSON.stringify(wrapBody(data.body))
    });
  }

  async put(data: HttpPayload<KeyedObject<any>>): Promise<any> {
    return Http.put({
      url: data.url,
      headers: data.headers,
      body: JSON.stringify(wrapBody(data.body))
    });
  }
}

export const JsonHttp = new JsonHttpClient();
