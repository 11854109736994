import React, { ReactElement } from "react";
import { Box, Fade } from "@mui/material";

interface Props {
  children: ReactElement<any, any>;
  show: boolean;
}

export function OptionalSection(props: Props) {
  const { children, show } = props;
  return (
    <Fade in={show} mountOnEnter={true} unmountOnExit={true}>
      <Box>{children}</Box>
    </Fade>
  );
}
