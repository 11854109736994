import { generateRandomId } from "../ext/id";
import { Listener, newListener } from "./Listener";

interface BusListener<T> {
  id: string;
  onEvent: (event: T) => void;
}

/**
 * Simple event bus
 */
export class EventBus<T> {
  private readonly listeners: BusListener<T>[];

  constructor() {
    this.listeners = [];
  }

  /**
   * Clear the bus of all listeners
   */
  clear() {
    const listeners = this.listeners;
    for (let i = listeners.length - 1; i >= 0; --i) {
      listeners.splice(i, 1);
    }
  }

  /**
   * Publish a message to the bus
   */
  publish(event: T) {
    const listeners = [...this.listeners];
    for (const listener of listeners) {
      Promise.resolve(listener).then((l) => {
        l.onEvent(event);
      });
    }
  }

  /**
   * Listen for bus messages
   * @param onEvent
   */
  subscribe(onEvent: (event: T) => void): Listener {
    const listener = {
      id: generateRandomId(),
      onEvent,
    };

    const listeners = this.listeners;
    listeners.push(listener);

    return newListener(() => {
      const index = listeners.findIndex((l) => l.id === listener.id);
      if (index >= 0) {
        listeners.splice(index, 1);
      }
    });
  }
}
