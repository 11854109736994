import { IS_DEBUG_MODE } from "./constants";

export class FeatureFlags {
  /**
   * Nothing is required
   */
  static DONT_ENFORCE_REQUIRED = IS_DEBUG_MODE;

  /**
   * Limit client to only a single picture per picture spot
   *
   * This is a report limitation
   */
  static SINGLE_PICTURE_UPLOAD = false;
}
