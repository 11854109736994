import React, { ChangeEvent, ReactElement, ReactNode } from "react";
import {
  FilledInputProps,
  InputBaseComponentProps,
  InputProps,
  OutlinedInputProps,
  TextField,
} from "@mui/material";
import { stringIsNotBlank } from "../util/ext/string";
import { debounce, DebouncedFunction } from "../util/helper/debounce";
import { INTERACTION_DEBOUNCE_TIME } from "../util/constant/constants";

export const MUTATE_NUMBER_ONLY = (value: string) => {
  return value.replace(/\D/g, "");
};

export const NUMBER_INPUT: InputBaseComponentProps = {
  inputMode: "numeric",
  pattern: "[0-9]*",
};

export interface TextInputProps {
  autoComplete: string;
  onChange: (content: string) => void;
  onBlur: () => void;
  onFocus: () => void;
  autoFocus: boolean;
  id: string;
  label: string;
  name: string;
  value: string;
  type: string;
  fullWidth: boolean;
  endAdornment: ReactElement<any, any>;
  inputRef: (ref: any) => void;
  disabled: boolean;
  multiline: boolean;
  minRows: number;
  maxRows: number;
  size: "small" | "medium";
  InputProps:
    | Partial<InputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>;
  inputProps: InputBaseComponentProps;
  sx: object;
  variant: "standard" | "outlined" | "filled";
  helperText: ReactNode;
  error: boolean;
}

/**
 * Simple text input which handles string input
 */
export class TextInput extends React.Component<Partial<TextInputProps>> {
  /**
   * Processes a change event and pulls the string content out
   *
   * @param $event
   */
  handleChange = ($event: ChangeEvent<HTMLInputElement>) => {
    const value = $event?.target?.value || "";
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  /**
   * Handle focus events
   */
  handleFocus = () => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus();
    }
  };

  /**
   * Handle blur events
   */
  handleBlur = () => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur();
    }
  };

  render() {
    const { onChange, onFocus, onBlur, ...rest } = this.props;
    const {
      sx,
      autoFocus,
      variant,
      name,
      value,
      inputRef,
      disabled,
      fullWidth,
      label,
      id,
      maxRows,
      minRows,
      multiline,
      size,
      helperText,
      error,
      InputProps,
      autoComplete,
      inputProps,
      type,
    } = rest;
    return (
      <TextField
        type={type}
        inputProps={inputProps}
        autoComplete={autoComplete}
        helperText={helperText}
        error={error}
        sx={sx}
        autoFocus={autoFocus}
        name={name}
        value={value}
        inputRef={inputRef}
        disabled={disabled}
        fullWidth={fullWidth}
        label={label}
        InputProps={InputProps}
        id={id}
        maxRows={maxRows}
        minRows={minRows}
        variant={variant}
        multiline={multiline}
        size={size}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      />
    );
  }
}

interface FastState {
  value: string;
}

interface FastProps extends Partial<TextInputProps> {
  mutate?: (value: string) => string;
}

/**
 * FastTextInput keeps its own state and scopes re-render passes to only its Component
 *
 * Periodically the FastTextInput will broadcast it's state back out to a parent component,
 * which allows it to "look" like a controlled component when really it is internally managed.
 *
 * This should help avoid the entire application re-rendering because a user inputted some text
 */
export class FastTextInput extends React.Component<FastProps, FastState> {
  /**
   * Debounced broadcast of the current text state to the parent
   */
  private readonly onBroadcastChange: DebouncedFunction;

  constructor(props: FastProps) {
    super(props);
    const { value } = props;
    this.state = {
      value: value ? (stringIsNotBlank(value) ? value.trim() : "") : "",
    };

    this.onBroadcastChange = debounce(
      this.handleBroadcastChange,
      INTERACTION_DEBOUNCE_TIME
    );
  }

  /**
   * Broadcast text state to the parent
   */
  handleBroadcastChange = () => {
    const { onChange } = this.props;
    const { value } = this.state;
    if (onChange) {
      onChange(value);
    }
  };

  /**
   * Handle updates to the value
   * @param value
   * @param broadcast
   */
  handleUpdateValue = (value: string, broadcast: boolean) => {
    const { mutate } = this.props;
    this.setState({ value: mutate ? mutate(value) : value }, () => {
      if (broadcast) {
        this.onBroadcastChange();
      } else {
        this.onBroadcastChange.cancel();
      }
    });
  };

  /**
   * On text changed we update our internal state and queue a broadcast
   * @param value
   */
  handleChange = (value: string) => {
    this.handleUpdateValue(value, true);
  };

  /**
   * Handle a DidUpdate where the outer prop.value has been updated
   * @param prevProps
   */
  handleValuePropUpdated = (prevProps: Readonly<Partial<TextInputProps>>) => {
    const currentPropValue = this.props.value;
    const currentStateValue = this.state.value;
    const previousPropsValue = prevProps.value;

    if (!currentPropValue) {
      // No value, do nothing
      return;
    }

    if (currentStateValue === currentPropValue) {
      // value prop is now synced up to our current state, no change
      return;
    }

    if (currentPropValue === previousPropsValue) {
      // No change in value prop has occurred, do nothing, we are done
      return;
    }

    // Value prop has changed from the outside, sync up our existing state but do not broadcast as a change
    this.handleUpdateValue(currentPropValue, false);
  };

  /**
   * If the parent prop.value is updated outside of our view, then a caller is most likely expecting this to behave like
   * a controlled prop and track the currently set prop.value
   *
   * As long as the prop.value is not currently equal to our tracked state.value, we cancel any queued broadcasts and then
   * update the state
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(
    prevProps: Readonly<Partial<TextInputProps>>,
    prevState: Readonly<FastState>,
    snapshot?: any
  ): void {
    this.handleValuePropUpdated(prevProps);
  }

  /**
   * When dying, immediately fire out the most recent state and cancel any future broadcasts
   */
  componentWillUnmount(): void {
    this.handleBroadcastChange();
    this.onBroadcastChange.cancel();
  }

  render() {
    const { onChange, value: ignoreMe, mutate, ...rest } = this.props;
    const { value } = this.state;
    return <TextInput {...rest} value={value} onChange={this.handleChange} />;
  }
}
