import { IS_DEBUG_MODE } from "./constants";

const DEV_URL = "https://emdjruesag.execute-api.us-west-2.amazonaws.com/dev";
const APP_URL = "https://c8ke747zf3.execute-api.us-west-2.amazonaws.com/prd";

/**
 * Base URL for API
 */
export function getUrl() {
  let baseUrl: string;
  if (IS_DEBUG_MODE) {
    baseUrl = DEV_URL;
  } else {
    baseUrl = APP_URL;
  }

  return baseUrl;
}

/**
 * Base URL for API
 */
export function getApiUrl() {
  return `${getUrl()}/api`;
}
