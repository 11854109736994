import { NavigationProps, WsaProps } from "../wsa/WsaViewModel";
import React from "react";
import { Box } from "@mui/material";
import { MARGIN_DEFAULT } from "../util/constant/constants";
import { Navigation } from "../ui/Navigation";
import { BinaryChoice } from "../ui/BinaryChoice";
import { Label } from "../ui/Label";
import { FastTextInput } from "../ui/TextInput";
import { TriState } from "../model/TriState";
import { WaterTreatmentPayload } from "../model/WaterTreatmentPayload";

interface Props
  extends Pick<
      WsaProps,
      keyof WaterTreatmentPayload | "onWaterTreatmentSubmit"
    >,
    NavigationProps {}

interface State extends WaterTreatmentPayload {}

const TREATMENT_SYSTEMS_TRUE = "Point of Use";
const TREATMENT_SYSTEMS_FALSE = "Whole House";

export class WaterTreatmentSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { onWaterTreatmentSubmit, onPreviousSection, ...rest } = props;
    this.state = {
      ...rest,
    };
  }

  handleTypeAndLeaks = (value: string) => {
    this.setState({ treatmentTypeAndLeaks: value });
  };

  handleSystems = (value: TriState) => {
    this.setState({
      treatmentSystems:
        value === TriState.TRUE
          ? TREATMENT_SYSTEMS_TRUE
          : value === TriState.FALSE
          ? TREATMENT_SYSTEMS_FALSE
          : "",
    });
  };

  handleRecommendations = (value: string) => {
    this.setState({ treatmentRecommendations: value });
  };

  handleSubmit = () => {
    const { ...rest } = this.state;
    const { onWaterTreatmentSubmit } = this.props;
    onWaterTreatmentSubmit(rest);
  };

  render() {
    const { onPreviousSection } = this.props;
    const {
      treatmentRecommendations,
      treatmentSystems,
      treatmentTypeAndLeaks,
    } = this.state;
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Water Treatment Type and Visible Leaks?"
            value={treatmentTypeAndLeaks}
            onChange={this.handleTypeAndLeaks}
            multiline={true}
            maxRows={6}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Water Treatment Systems" />
          <BinaryChoice
            yes={TREATMENT_SYSTEMS_TRUE}
            no={TREATMENT_SYSTEMS_FALSE}
            value={
              treatmentSystems === TREATMENT_SYSTEMS_TRUE
                ? TriState.TRUE
                : treatmentSystems === TREATMENT_SYSTEMS_FALSE
                ? TriState.FALSE
                : TriState.UNKNOWN
            }
            onChange={this.handleSystems}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Water Treatment Recommendations?"
            value={treatmentRecommendations}
            onChange={this.handleRecommendations}
            multiline={true}
            maxRows={6}
          />
        </Box>
        <Box display="flex" mb={MARGIN_DEFAULT}>
          <Navigation
            onNext={this.handleSubmit}
            onPrevious={onPreviousSection}
          />
        </Box>
      </Box>
    );
  }
}
