import { NavigationProps, WsaProps } from "../wsa/WsaViewModel";
import React from "react";
import { Box } from "@mui/material";
import { MARGIN_DEFAULT } from "../util/constant/constants";
import { isProvided, RequiredField } from "../ui/RequiredField";
import { Navigation } from "../ui/Navigation";
import { PassFail } from "../ui/BinaryChoice";
import { Dropdown } from "../ui/Dropdown";
import { simpleDropdownItem } from "../ui/dropdown/DropdownItem";
import { Label } from "../ui/Label";
import {
  FastTextInput,
  MUTATE_NUMBER_ONLY,
  NUMBER_INPUT,
} from "../ui/TextInput";
import { ToiletPayload } from "../model/ToiletPayload";
import { TriState } from "../model/TriState";
import { FeatureFlags } from "../util/constant/flags";

interface Props
  extends Pick<WsaProps, keyof ToiletPayload | "onToiletSubmit">,
    NavigationProps {}

interface State extends ToiletPayload {
  showErrors: boolean;
}

const SUPPLY_LINES = [
  simpleDropdownItem(`Plastic`),
  simpleDropdownItem(`Metal`),
  simpleDropdownItem(`Stainless Braided`),
  simpleDropdownItem(`Other`),
];

export class ToiletSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { onToiletSubmit, onPreviousSection, ...rest } = props;
    this.state = {
      ...rest,
      showErrors: false,
    };
  }

  handleSupplyLines = (value: string) => {
    this.setState({ toiletSupplyLine: value });
  };

  handleShutoffValves = (value: TriState) => {
    this.setState({ toiletShutoffValve: value });
  };

  handleShutoffValvesCount = (value: string) => {
    this.setState({ toiletShutoffCount: value });
  };

  handleDyeTest = (value: TriState) => {
    this.setState({ toiletDyeTest: value });
  };

  handleSeal = (value: TriState) => {
    this.setState({ toiletSeal: value });
  };

  handleWaterFill = (value: TriState) => {
    this.setState({ toiletWaterFillDevice: value });
  };

  handleWaterFillCount = (value: string) => {
    this.setState({ toiletWaterFillDeviceCount: value });
  };

  handleRecommendations = (value: string) => {
    this.setState({ toiletRecommendations: value });
  };

  canSubmit = () => {
    if (FeatureFlags.DONT_ENFORCE_REQUIRED) {
      return true;
    }

    const { toiletSupplyLine } = this.state;
    return isProvided(toiletSupplyLine);
  };

  handleSubmit = () => {
    if (this.canSubmit()) {
      const { showErrors, ...rest } = this.state;
      const { onToiletSubmit } = this.props;
      onToiletSubmit(rest);
    } else {
      this.setState({ showErrors: true });
    }
  };

  render() {
    const { onPreviousSection } = this.props;
    const {
      showErrors,
      toiletSupplyLine,
      toiletRecommendations,
      toiletShutoffValve,
      toiletDyeTest,
      toiletSeal,
      toiletShutoffCount,
      toiletWaterFillDevice,
      toiletWaterFillDeviceCount,
    } = this.state;
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Dropdown
            label="Toilet Water Supply Lines"
            value={toiletSupplyLine}
            onChange={this.handleSupplyLines}
            items={SUPPLY_LINES}
          />
          <RequiredField show={showErrors} value={toiletSupplyLine} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Toilet Shut off Valves" />
          <PassFail
            value={toiletShutoffValve}
            onChange={this.handleShutoffValves}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Shutoff Valves to Toilets (Quantity)"
            value={toiletShutoffCount}
            onChange={this.handleShutoffValvesCount}
            type="tel"
            inputProps={NUMBER_INPUT}
            mutate={MUTATE_NUMBER_ONLY}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Water Fill Device" />
          <PassFail
            value={toiletWaterFillDevice}
            onChange={this.handleWaterFill}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Water Fill Device (Quantity)"
            value={toiletWaterFillDeviceCount}
            onChange={this.handleWaterFillCount}
            type="tel"
            inputProps={NUMBER_INPUT}
            mutate={MUTATE_NUMBER_ONLY}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Toilet Sea?" />
          <PassFail value={toiletSeal} onChange={this.handleSeal} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Toilet Dye Test?" />
          <PassFail value={toiletDyeTest} onChange={this.handleDyeTest} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Toilet Water Supply Recommendations?"
            value={toiletRecommendations}
            onChange={this.handleRecommendations}
            multiline={true}
            maxRows={6}
          />
        </Box>
        <Box display="flex" mb={MARGIN_DEFAULT}>
          <Navigation
            onNext={this.handleSubmit}
            onPrevious={onPreviousSection}
          />
        </Box>
      </Box>
    );
  }
}
