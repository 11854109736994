import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import React, { ReactElement } from "react";
import { Logger } from "../../util/logger/Logger";
import { Box } from "@mui/material";

const MAX_FILE_SIZE_IN_BYTES = 100 * 1024 * 1024;

interface Props {
  /**
   * Disable the dropzone interactive
   */
  disabled?: boolean;

  /**
   * When files are successfully dropped
   * @param files
   */
  onAccepted: (files: File[]) => void;

  /**
   * When files fail to drop for whatever reason
   * @param files
   */
  onRejected: (files: FileRejection[]) => void;

  /**
   * React Children
   */
  children: (props: {
    open: () => void;
  }) => ReactElement<any, any> | ReactElement<any, any>[];
}

interface State {
  hovering: boolean;
}

/**
 * A dropzone which allows you to drag and drop files
 */
export class FileDropzone extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hovering: false,
    };
  }

  /**
   * Upon drag enter, set state to hovering
   */
  handleDragEnter = () => {
    this.setState({ hovering: true });
  };

  /**
   * Upon drag leave set state to not-hovering
   */
  handleDragLeave = () => {
    this.setState({ hovering: false });
  };

  /**
   * Callback when files dropped are accepted
   *
   * @param files
   * @param event
   */
  handleFilesAccepted = (files: File[], event: DropEvent) => {
    Logger.log("Files accepted: ", files, event);
    const { onAccepted } = this.props;
    onAccepted(files);
  };

  /**
   * Callback when files dropped are rejected
   *
   * @param files
   * @param event
   */
  handleFilesRejected = (files: FileRejection[], event: DropEvent) => {
    Logger.warn("Files rejected: ", files, event);
    const { onRejected } = this.props;
    onRejected(files);
  };

  render() {
    const { children, disabled } = this.props;
    const { hovering } = this.state;
    return (
      <DragAndDropZone
        disabled={disabled}
        maxSize={MAX_FILE_SIZE_IN_BYTES}
        hovering={hovering}
        onDragEnter={this.handleDragEnter}
        onDragLeave={this.handleDragLeave}
        onFilesAccepted={this.handleFilesAccepted}
        onFilesRejected={this.handleFilesRejected}
      >
        {children}
      </DragAndDropZone>
    );
  }
}

interface DragDropProps {
  hovering: boolean;
  disabled?: boolean;
  maxSize?: number;
  onDragEnter: () => void;
  onDragLeave: () => void;
  onFilesAccepted: (files: File[], event: DropEvent) => void;
  onFilesRejected: (files: FileRejection[], event: DropEvent) => void;
  children: (props: {
    open: () => void;
  }) => ReactElement<any, any> | ReactElement<any, any>[];
}

const ACCEPTED_FILE_TYPES = ["image/*", "video/*"];
const FILL_PARENT_STYLE: object = { width: "100%", height: "100%" };

function DragAndDropZone(props: DragDropProps) {
  const {
    disabled,
    maxSize,
    onDragEnter,
    onDragLeave,
    onFilesAccepted,
    onFilesRejected,
    children,
  } = props;
  return (
    <Dropzone
      accept={ACCEPTED_FILE_TYPES}
      disabled={disabled}
      maxSize={maxSize}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragEnter}
      onDropRejected={onFilesRejected}
      onDropAccepted={onFilesAccepted}
      noClick={true}
    >
      {({ getRootProps, getInputProps, open }) => (
        <Box m="auto" width="100%" height="100%">
          <Box {...getRootProps()} sx={FILL_PARENT_STYLE}>
            <input {...getInputProps()} capture={undefined} />
            {children({ open })}
          </Box>
        </Box>
      )}
    </Dropzone>
  );
}
