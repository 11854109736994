import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

export function ErrorDialog(data: {
  open: boolean;
  error: Error | undefined;
  onClose: () => void;
}) {
  const { open, error, onClose } = data;
  return (
    <Dialog open={open || !!error} onClose={onClose}>
      <DialogTitle>
        {error ? "Something went wrong" : "Assessment submitted!"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error
            ? `Here's what we know: ${
                error.message ? error.message : "An unexpected error occurred"
              }. Please try again later.`
            : "Your assessment has been submitted, and a report will be generated and emailed to the customer!"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Okay</Button>
      </DialogActions>
    </Dialog>
  );
}
