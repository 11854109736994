import React from "react";
import { Box, Button } from "@mui/material";
import { TriState } from "../model/TriState";
import { MARGIN_DEFAULT } from "../util/constant/constants";

interface Props {
  value: TriState;
  onChange: (value: TriState) => void;
}

interface InternalProps extends Props {
  yes: string;
  no: string;
}

export function YesNo(props: Props) {
  return <BinaryChoice {...props} yes="Yes" no="No" />;
}

export function PassFail(props: Props) {
  return <BinaryChoice {...props} yes="Pass" no="Fail" />;
}

export function OperationalStatus(props: Props) {
  return <BinaryChoice {...props} yes="Operational" no="Non-Operational" />;
}

export class BinaryChoice extends React.Component<InternalProps> {
  handleYes = () => {
    const { onChange } = this.props;
    onChange(TriState.TRUE);
  };

  handleNo = () => {
    const { onChange } = this.props;
    onChange(TriState.FALSE);
  };

  render() {
    const { value, yes, no } = this.props;
    return (
      <Box display="flex" flexDirection="row" flexWrap="nowrap">
        <Button
          variant="outlined"
          color={value === TriState.TRUE ? "primary" : "neutral"}
          onClick={this.handleYes}
        >
          {yes}
        </Button>
        <Box ml={MARGIN_DEFAULT} />
        <Button
          variant="outlined"
          color={value === TriState.FALSE ? "primary" : "neutral"}
          onClick={this.handleNo}
        >
          {no}
        </Button>
      </Box>
    );
  }
}
