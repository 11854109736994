import { Attachment } from "../model/Attachment";
import React from "react";
import { Logger } from "../util/logger/Logger";
import { FileZone } from "./attachment/FileZone";

interface Props {
  attachments: Attachment[];
  onCommitAttachments: (attachments: Attachment[]) => void;
}

interface State {
  attachments: Attachment[];
}

export class ImageUpload extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { attachments } = props;
    this.state = {
      attachments,
    };
  }

  handleCommitAttachments = () => {
    const { onCommitAttachments } = this.props;
    const { attachments } = this.state;
    onCommitAttachments(attachments);
  };

  /**
   * On attachment added
   * @param attachments
   */
  handleAttachmentAdded = (attachments: Attachment[]) => {
    this.setState(
      { attachments: [...this.state.attachments, ...attachments] },
      this.handleCommitAttachments
    );
  };

  /**
   * On attachment removed
   * @param attachment
   */
  handleAttachmentRemoved = (attachment: Attachment) => {
    const attachments = [...this.state.attachments];
    const index = attachments.findIndex((a) => a.tempId === attachment.tempId);

    // Make sure we revoke the URL to free up memory
    if (index >= 0) {
      attachments.splice(index, 1);
      if (attachment.isDataObjectUrl) {
        Logger.log("Revoke objectURL: ", attachment);
        URL.revokeObjectURL(attachment.data);
      }
    }

    this.setState({ attachments }, this.handleCommitAttachments);
  };

  /**
   * On attachment view
   * @param attachment
   */
  handleAttachmentViewed = (attachment: Attachment) => {
    // TODO View
  };

  /**
   * Release the URL object for any of the attachments we have
   */
  handleReleaseFileMemory = () => {
    const { attachments } = this.state;

    // Don't need to setState since this component is dead
    for (const attachment of attachments) {
      if (attachment.isDataObjectUrl) {
        Logger.log("Revoke objectURL", attachment);
        URL.revokeObjectURL(attachment.data);
      }
    }
  };

  componentWillUnmount(): void {
    this.handleReleaseFileMemory();
  }

  render() {
    const { attachments } = this.state;
    return (
      <FileZone
        attachments={attachments}
        onAttachmentAdded={this.handleAttachmentAdded}
        onAttachmentRemoved={this.handleAttachmentRemoved}
        onAttachmentViewed={this.handleAttachmentViewed}
      />
    );
  }
}
