import { NavigationProps, WsaProps } from "../wsa/WsaViewModel";
import React from "react";
import { Box } from "@mui/material";
import { MARGIN_DEFAULT } from "../util/constant/constants";
import { isProvided, RequiredField } from "../ui/RequiredField";
import { Navigation } from "../ui/Navigation";
import { OperationalStatus } from "../ui/BinaryChoice";
import { Dropdown } from "../ui/Dropdown";
import { simpleDropdownItem } from "../ui/dropdown/DropdownItem";
import { Label } from "../ui/Label";
import { FastTextInput } from "../ui/TextInput";
import { SinkPayload } from "../model/SinkPayload";
import { TriState } from "../model/TriState";
import { FeatureFlags } from "../util/constant/flags";

interface Props
  extends Pick<WsaProps, keyof SinkPayload | "onSinkSubmit">,
    NavigationProps {
}

interface State extends SinkPayload {
  showErrors: boolean;
}

const SUPPLY_LINES = [
  simpleDropdownItem(`Plastic`),
  simpleDropdownItem(`Metal`),
  simpleDropdownItem(`Stainless Braided`),
  simpleDropdownItem(`Other`)
];

export class SinkSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { onSinkSubmit, onPreviousSection, ...rest } = props;
    this.state = {
      ...rest,
      showErrors: false
    };
  }

  handleSupplyLines = (value: string) => {
    this.setState({ sinkSupplyLine: value });
  };

  handleShutoffValves = (value: TriState) => {
    this.setState({ sinkShutoffValve: value });
  };

  handleRecommendations = (value: string) => {
    this.setState({ sinkRecommendations: value });
  };

  canSubmit = () => {
    if (FeatureFlags.DONT_ENFORCE_REQUIRED) {
      return true;
    }

    const { sinkSupplyLine } = this.state;
    return isProvided(sinkSupplyLine);
  };

  handleSubmit = () => {
    if (this.canSubmit()) {
      const { showErrors, ...rest } = this.state;
      const { onSinkSubmit } = this.props;
      onSinkSubmit(rest);
    } else {
      this.setState({ showErrors: true });
    }
  };

  render() {
    const { onPreviousSection } = this.props;
    const {
      showErrors,
      sinkSupplyLine,
      sinkRecommendations,
      sinkShutoffValve
    } = this.state;
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Dropdown
            label="Sink Water Supply Lines"
            value={sinkSupplyLine}
            onChange={this.handleSupplyLines}
            items={SUPPLY_LINES}
          />
          <RequiredField
            show={showErrors}
            value={sinkSupplyLine}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Sink Shut off Valves"/>
          <OperationalStatus
            value={sinkShutoffValve}
            onChange={this.handleShutoffValves}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Sink Water Supply Recommendations?"
            value={sinkRecommendations}
            onChange={this.handleRecommendations}
            multiline={true}
            maxRows={6}
          />
        </Box>
        <Box display="flex" mb={MARGIN_DEFAULT}>
          <Navigation
            onNext={this.handleSubmit}
            onPrevious={onPreviousSection}
          />
        </Box>
      </Box>
    );
  }
}
