import { Box, Typography } from "@mui/material";
import { MARGIN_DEFAULT } from "../util/constant/constants";
import React from "react";

interface Props {
  name: string;
}

export function Label(props: Props) {
  const { name } = props;
  return (
    <Box my={MARGIN_DEFAULT} component="div">
      <Typography variant="body2" component="div">
        <Box component="div" fontWeight={500}>
          {name}
        </Box>
      </Typography>
    </Box>
  );
}
