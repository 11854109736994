import { FastTextInput, TextInputProps } from "./TextInput";
import React, { FormEvent } from "react";
import { Box } from "@mui/material";

interface Props extends Partial<TextInputProps> {
  nextRef?: HTMLInputElement;
  onSubmit?: () => void;
}

export class ProgressTextInput extends React.Component<Props> {
  handleSubmit = ($event: FormEvent<any>) => {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    const { nextRef, onSubmit } = this.props;
    if (nextRef) {
      nextRef.focus();
    } else {
      if (onSubmit) {
        onSubmit();
      }
    }
  };

  render() {
    const { nextRef, onSubmit, ...rest } = this.props;
    return (
      <Box width="100%">
        <form onSubmit={this.handleSubmit}>
          <FastTextInput {...rest} />
        </form>
      </Box>
    );
  }
}
