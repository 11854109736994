import React from "react";
import { useWsa } from "../WsaViewModel";
import {
  Box,
  Container,
  CssBaseline,
  Paper,
  ThemeProvider,
} from "@mui/material";
import { WsaTheme } from "./WsaTheme";
import { MARGIN_DEFAULT } from "../../util/constant/constants";
import { Section } from "../model/WsaSection";
import { UserInfoSection } from "../../screens/UserInfoSection";
import { AddressInfoSection } from "../../screens/AddressInfoSection";
import { WaterPressureSection } from "../../screens/WaterPressureSection";
import { InteriorWaterSection } from "../../screens/InteriorWaterSection";
import { SinkSection } from "../../screens/SinkSection";
import { ToiletSection } from "../../screens/ToiletSection";
import { WashingMachineSection } from "../../screens/WashingMachineSection";
import { OtherWaterSection } from "../../screens/OtherWaterSection";
import { WaterTreatmentSection } from "../../screens/WaterTreatmentSection";
import { SewageSection } from "../../screens/SewageSection";
import { ExteriorSection } from "../../screens/ExteriorSection";
import { SmartValveSection } from "../../screens/SmartValveSection";
import { SubmitStateContext } from "../../context/SubmitStateContext";
import WsaHeaderImage from "../../images/wsa.png";
import { ErrorDialog } from "./ErrorDialog";

const PADDING_WIDTH = { lg: undefined, xs: "100%" };
const PADDING_MARGIN = { lg: "auto", xs: 0 };
const CONTAINER_STYLE = {
  minHeight: "100vh",
  width: "100vw",
};

export function WsaSheet() {
  return (
    <Box id="beagle-wsa" component="div" minHeight="100vh">
      <CssBaseline />
      <ThemeProvider theme={WsaTheme}>
        <Box bgcolor="primary.light" minHeight="100%" width="100%">
          <Container maxWidth="md" component="div" sx={CONTAINER_STYLE}>
            <Padding>
              <Header />
            </Padding>
            <Padding>
              <Content />
            </Padding>
          </Container>
        </Box>
      </ThemeProvider>
    </Box>
  );
}

interface PaddingProps {}

const Padding: React.FunctionComponent<PaddingProps> = ({ children }) => {
  return (
    <Box width="100%" minHeight="100%" display="flex" flexWrap="nowrap">
      <Box
        minWidth="80%"
        minHeight="80%"
        width={PADDING_WIDTH}
        m={PADDING_MARGIN}
        pt={MARGIN_DEFAULT * 2}
      >
        {children}
      </Box>
    </Box>
  );
};

function Header() {
  return (
    <Box maxWidth="100%">
      <img src={WsaHeaderImage} alt="WSA Header" width="100%" />
    </Box>
  );
}

function Content() {
  const props = useWsa();
  const { section, onCloseError, onResetSession, ...rest } = props;
  const { submitError, submitComplete } = rest;
  // We use rest here instead of extracting and then allocating a new object
  // Since the rest contains the submit state
  return (
    <SubmitStateContext.Provider value={rest}>
      <Box pb={MARGIN_DEFAULT * 4} width="100%">
        <Paper>
          <Box px={MARGIN_DEFAULT} py={MARGIN_DEFAULT * 2}>
            {section === Section.CUSTOMER && <UserInfoSection {...rest} />}
            {section === Section.ADDRESS && <AddressInfoSection {...rest} />}
            {section === Section.WATER_PRESSURE && (
              <WaterPressureSection {...rest} />
            )}
            {section === Section.INTERIOR_WATER && (
              <InteriorWaterSection {...rest} />
            )}
            {section === Section.SINK && <SinkSection {...rest} />}
            {section === Section.TOILET && <ToiletSection {...rest} />}
            {section === Section.WASHING_MACHINE && (
              <WashingMachineSection {...rest} />
            )}
            {section === Section.OTHER_WATER && <OtherWaterSection {...rest} />}
            {section === Section.WATER_TREATMENT && (
              <WaterTreatmentSection {...rest} />
            )}
            {section === Section.SEWAGE && <SewageSection {...rest} />}
            {section === Section.EXTERIOR && <ExteriorSection {...rest} />}
            {section === Section.SMART_VALVE && <SmartValveSection {...rest} />}
          </Box>
        </Paper>

        <ErrorDialog
          open={submitComplete}
          error={submitError}
          onClose={submitError ? onCloseError : onResetSession}
        />
      </Box>
    </SubmitStateContext.Provider>
  );
}
