type Request = { url: string; init: object };

export class NetworkError extends Error {
  readonly error: Error;
  readonly request: Request;
  readonly code: number;

  constructor(error: Error, message: string, code: number, request: Request) {
    super(message);
    this.error = error;
    this.code = code;
    this.request = request;
  }
}

/**
 * Create a new network error
 *
 * @param error
 * @param message
 * @param req
 * @param res
 */
export function createNetworkError(
  error: Error,
  message: string,
  req: Request,
  res: Response
) {
  return new NetworkError(error, message, res.status, req);
}

/**
 * Is this a network error, or does it have the shape of one
 * @param error
 */
export function isNetworkError(error: any): error is NetworkError {
  return !!error.error && !!error.request && !!error.code && !!error.message;
}
