import { Box, Button } from "@mui/material";
import React from "react";
import { useSubmitState } from "../context/SubmitStateContext";

interface Props {
  onPrevious: () => void;
  onNext: () => void;

  isFirstScreen?: boolean;
  isFinalScreen?: boolean;
}

export function Navigation(props: Props) {
  const { onPrevious, onNext, isFirstScreen, isFinalScreen } = props;
  const { submitting } = useSubmitState();
  return (
    <Box display="flex" flexDirection="row" flexWrap="nowrap" width="100%">
      <Box visibility={isFirstScreen ? "hidden" : "visible"}>
        <Button
          variant="text"
          color="primary"
          onClick={onPrevious}
          disabled={submitting}
        >
          Previous
        </Button>
      </Box>
      <Box flexGrow={1} />
      <Button
        variant="text"
        color="primary"
        onClick={onNext}
        disabled={submitting}
      >
        {isFinalScreen ? "Submit" : "Next"}
      </Button>
    </Box>
  );
}
