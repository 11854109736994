import { NavigationProps, WsaProps } from "../wsa/WsaViewModel";
import React from "react";
import { Box } from "@mui/material";
import { MARGIN_DEFAULT } from "../util/constant/constants";
import { Navigation } from "../ui/Navigation";
import { BinaryChoice, PassFail, YesNo } from "../ui/BinaryChoice";
import { Dropdown } from "../ui/Dropdown";
import { simpleDropdownItem } from "../ui/dropdown/DropdownItem";
import { Label } from "../ui/Label";
import { FastTextInput } from "../ui/TextInput";
import { SmartValvePayload } from "../model/SmartValvePayload";
import { TriState } from "../model/TriState";

interface Props
  extends Pick<WsaProps, keyof SmartValvePayload | "onSmartValveSubmit">,
    NavigationProps {}

interface State extends SmartValvePayload {}

const PIPE_SIZE = [
  simpleDropdownItem(`3/4"`),
  simpleDropdownItem(`1"`),
  simpleDropdownItem(`1-1/4"`),
  simpleDropdownItem(`1-1/2"`),
  simpleDropdownItem(`2"`),
];

const LOCATION_TRUE = "Indoor";
const LOCATION_FALSE = "Outdoor";

const INSTALL_TYPE_TRUE = "Standard";
const INSTALL_TYPE_FALSE = "Complex";

export class SmartValveSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { onSmartValveSubmit, onPreviousSection, ...rest } = props;
    this.state = {
      ...rest,
    };
  }

  handleValveLocation = (value: TriState) => {
    this.setState({
      smartValveLocation:
        value === TriState.TRUE
          ? LOCATION_TRUE
          : value === TriState.FALSE
          ? LOCATION_FALSE
          : "",
    });
  };

  handleOutlet = (value: TriState) => {
    this.setState({ smartValveOutletWithinReach: value });
  };

  handleElectrician = (value: TriState) => {
    this.setState({ smartValveElectricianNeeded: value });
  };

  handleWifi = (value: TriState) => {
    this.setState({ smartValveWifiSignal: value });
  };

  handleDigging = (value: TriState) => {
    this.setState({ smartValveDigging: value });
  };

  handleDepth = (value: string) => {
    this.setState({ smartValveWaterLineDepth: value });
  };

  handleUtilitiesMarked = (value: TriState) => {
    this.setState({ smartValveUtilitiesMarked: value });
  };

  handleSpecialTools = (value: string) => {
    this.setState({ smartValveSpecialTools: value });
  };

  handlePrv = (value: TriState) => {
    this.setState({ smartValvePrvReplace: value });
  };

  handlePipeSize = (value: string) => {
    this.setState({ smartValveMainPipeSize: value });
  };

  handlePipeMaterial = (value: string) => {
    this.setState({ smartValveMainPipeMaterial: value });
  };

  handleInstallType = (value: TriState) => {
    this.setState({
      smartValveInstallationType:
        value === TriState.TRUE
          ? INSTALL_TYPE_TRUE
          : value === TriState.FALSE
          ? INSTALL_TYPE_FALSE
          : "",
    });
  };

  handleRecommendations = (value: string) => {
    this.setState({ smartValveRecommendations: value });
  };

  handleSubmit = () => {
    const { ...rest } = this.state;
    const { onSmartValveSubmit } = this.props;
    onSmartValveSubmit(rest);
  };

  render() {
    const { onPreviousSection } = this.props;
    const {
      smartValveRecommendations,
      smartValveDigging,
      smartValveElectricianNeeded,
      smartValveInstallationType,
      smartValveLocation,
      smartValveMainPipeMaterial,
      smartValveMainPipeSize,
      smartValveOutletWithinReach,
      smartValvePrvReplace,
      smartValveSpecialTools,
      smartValveUtilitiesMarked,
      smartValveWaterLineDepth,
      smartValveWifiSignal,
    } = this.state;
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Valve Location" />
          <BinaryChoice
            yes={LOCATION_TRUE}
            no={LOCATION_FALSE}
            value={
              smartValveLocation === LOCATION_TRUE
                ? TriState.TRUE
                : smartValveLocation === LOCATION_FALSE
                ? TriState.FALSE
                : TriState.UNKNOWN
            }
            onChange={this.handleValveLocation}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Outlet within reach of unit?" />
          <YesNo
            value={smartValveOutletWithinReach}
            onChange={this.handleOutlet}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Electrician Needed?" />
          <YesNo
            value={smartValveElectricianNeeded}
            onChange={this.handleElectrician}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="WiFi Signal Strength" />
          <PassFail value={smartValveWifiSignal} onChange={this.handleWifi} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Install Type" />
          <BinaryChoice
            yes={INSTALL_TYPE_TRUE}
            no={INSTALL_TYPE_FALSE}
            value={
              smartValveInstallationType === INSTALL_TYPE_TRUE
                ? TriState.TRUE
                : smartValveInstallationType === INSTALL_TYPE_FALSE
                ? TriState.FALSE
                : TriState.UNKNOWN
            }
            onChange={this.handleInstallType}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Dropdown
            label="Main Pipe Size"
            value={smartValveMainPipeSize}
            onChange={this.handlePipeSize}
            items={PIPE_SIZE}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Main Pipe Material"
            value={smartValveMainPipeMaterial}
            onChange={this.handlePipeMaterial}
            multiline={true}
            maxRows={6}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Digging Necessary?" />
          <YesNo value={smartValveDigging} onChange={this.handleDigging} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Water Line Depth?"
            value={smartValveWaterLineDepth}
            onChange={this.handleDepth}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Utilities Must Be Marked?" />
          <YesNo
            value={smartValveUtilitiesMarked}
            onChange={this.handleUtilitiesMarked}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Are special tools needed?"
            value={smartValveSpecialTools}
            onChange={this.handleSpecialTools}
            multiline={true}
            maxRows={6}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Is PRV replacement needed?" />
          <YesNo value={smartValvePrvReplace} onChange={this.handlePrv} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Smart Valve Recommendations?"
            value={smartValveRecommendations}
            onChange={this.handleRecommendations}
            multiline={true}
            maxRows={6}
          />
        </Box>
        <Box display="flex" mb={MARGIN_DEFAULT}>
          <Navigation
            isFinalScreen={true}
            onNext={this.handleSubmit}
            onPrevious={onPreviousSection}
          />
        </Box>
      </Box>
    );
  }
}
