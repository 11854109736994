import { NavigationProps, WsaProps } from "../wsa/WsaViewModel";
import React from "react";
import { Box } from "@mui/material";
import { MARGIN_DEFAULT } from "../util/constant/constants";
import { Navigation } from "../ui/Navigation";
import { YesNo } from "../ui/BinaryChoice";
import { Dropdown } from "../ui/Dropdown";
import { simpleDropdownItem } from "../ui/dropdown/DropdownItem";
import { Label } from "../ui/Label";
import { FastTextInput } from "../ui/TextInput";
import { SewagePayload } from "../model/SewagePayload";
import { TriState } from "../model/TriState";

interface Props
  extends Pick<WsaProps, keyof SewagePayload | "onSewageSubmit">,
    NavigationProps {}

interface State extends SewagePayload {}

const PIPING = [
  simpleDropdownItem(`Yes`),
  simpleDropdownItem(`No`),
  simpleDropdownItem(`Operational`),
  simpleDropdownItem(`Non-Operational`),
];

export class SewageSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { onSewageSubmit, onPreviousSection, ...rest } = props;
    this.state = {
      ...rest,
    };
  }

  handleTank = (value: TriState) => {
    this.setState({ sewageTank: value });
  };

  handleDischarge = (value: string) => {
    this.setState({ sewageDischargePiping: value });
  };

  handlePit = (value: string) => {
    this.setState({ sewagePitAndPump: value });
  };

  handleRecommendations = (value: string) => {
    this.setState({ sewageRecommendations: value });
  };

  handleSubmit = () => {
    const { ...rest } = this.state;
    const { onSewageSubmit } = this.props;
    onSewageSubmit(rest);
  };

  render() {
    const { onPreviousSection } = this.props;
    const {
      sewageRecommendations,
      sewageDischargePiping,
      sewagePitAndPump,
      sewageTank,
    } = this.state;
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Well/Pump Tank?" />
          <YesNo value={sewageTank} onChange={this.handleTank} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Dropdown
            label="Sewage Pump and Discharge Piping?"
            value={sewageDischargePiping}
            onChange={this.handleDischarge}
            items={PIPING}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Dropdown
            label="Sewage Ejector Pit and Pump?"
            value={sewagePitAndPump}
            onChange={this.handlePit}
            items={PIPING}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Sewage/Sump Pump Recommendations?"
            value={sewageRecommendations}
            onChange={this.handleRecommendations}
            multiline={true}
            maxRows={6}
          />
        </Box>
        <Box display="flex" mb={MARGIN_DEFAULT}>
          <Navigation
            onNext={this.handleSubmit}
            onPrevious={onPreviousSection}
          />
        </Box>
      </Box>
    );
  }
}
