import { Attachment } from "../../model/Attachment";
import { Logger } from "../../util/logger/Logger";
import { JsonHttp } from "../../http/client/JsonHttp";
import { getApiUrl, getUrl } from "../../util/constant/api";
import { BlobHttp } from "../../http/client/BloblHttp";
import { WsaPayload } from "../model/WsaPayload";
import { TriState } from "../../model/TriState";

async function addAttachment(
  email: string,
  attachment: Attachment
): Promise<string> {
  const { name, file } = attachment;

  const s3FileName = `wsa-${email}-${name}`;
  Logger.log("Get S3 url for attachment: ", name, s3FileName);
  const { preSignedURL } = await JsonHttp.post({
    url: `${getApiUrl()}/jobs/upload-url`,
    body: {
      file_name: s3FileName,
    },
  });

  Logger.log("Upload file to AWS: ", preSignedURL);
  await BlobHttp.put({
    url: preSignedURL,
    body: file,
  });

  const url = encodeURIComponent(s3FileName);
  Logger.log("Tell server about AWS url", url);
  return url;
}

function attachPhotos(
  runners: Promise<string>[],
  email: string,
  attachments: Attachment[]
) {
  if (attachments.length > 0) {
    for (const a of attachments) {
      runners.push(
        addAttachment(email, a).catch((e) => {
          Logger.error(e, "Error adding attachment");

          Logger.log("Swallow the attachment error and move on.");
          return "";
        })
      );
    }
  }
}

function asString(tristate: TriState): string {
  return tristate === TriState.UNKNOWN
    ? ""
    : tristate === TriState.TRUE
    ? "Yes"
    : tristate === TriState.FALSE
    ? "No"
    : "";
}

export class WsaApi {
  /**
   * Submit a WSA
   *
   * @param data
   */
  static async submit(data: WsaPayload): Promise<void> {
    const {
      email,
      waterHeaterTankLocationPictures,
      waterHeaterRatingPlatePictures,
      waterPressureGaugePictures,
    } = data;

    const photoJobs: Promise<string>[] = [];
    attachPhotos(photoJobs, email, waterHeaterTankLocationPictures);
    attachPhotos(photoJobs, email, waterPressureGaugePictures);
    attachPhotos(photoJobs, email, waterHeaterRatingPlatePictures);

    const tankLocationPicturesLength = waterHeaterTankLocationPictures.length;
    const pressureGaugeLength = waterPressureGaugePictures.length;
    const ratingPlatePicturesLength = waterHeaterRatingPlatePictures.length;
    const uploadUrls = await Promise.all(photoJobs);

    const tankLocationUrls: string[] = [];
    const pressureGaugeUrls: string[] = [];
    const ratingPlateUrls: string[] = [];

    // First photos are the tank locations
    if (tankLocationPicturesLength > 0) {
      for (let i = 0; i < tankLocationPicturesLength; ++i) {
        const pic = uploadUrls[i];
        if (pic) {
          tankLocationUrls.push(pic);
        }
      }
    }

    // Then its the pressure gauge
    if (pressureGaugeLength > 0) {
      for (let i = 0; i < pressureGaugeLength; ++i) {
        // Offset this based on the previous urls
        const pic = uploadUrls[tankLocationPicturesLength + i];
        if (pic) {
          pressureGaugeUrls.push(pic);
        }
      }
    }

    // Then its rating plate photos
    if (ratingPlatePicturesLength > 0) {
      for (let i = 0; i < ratingPlatePicturesLength; ++i) {
        // Offset this based on the previous urls
        const pic =
          uploadUrls[tankLocationPicturesLength + pressureGaugeLength + i];
        if (pic) {
          ratingPlateUrls.push(pic);
        }
      }
    }

    Logger.log("URLS: ", {
      tankLocationUrls,
      pressureGaugeUrls,
      ratingPlateUrls,
    });

    const { firstName, lastName, phoneNumber } = data;
    const { address1, address2, city, zipCode } = data;
    const {
      pressureReducingValveSize,
      pressureReducingValveWorkingCondition,
      mainShutoffValveSize,
      mainShutoffValveWorkingCondition,
    } = data;
    const {
      waterHeaterRecommendations,
      pressureReducingRecommendations,
      mainShutoffRecommendations,
      circulationSystemPresent,
      waterHeaterType,
      waterHeaterGallons,
      dischargePipingAcceptable,
      panAndDrainPresent,
      waterHeaterExpansionTank,
      waterHeaterExpansionTankTested,
    } = data;
    const {
      interiorWaterSource,
      interiorMainWaterPipingType,
      interiorVisiblePipesRiskOfFreezing,
      interiorRecommendations,
    } = data;
    const { sinkSupplyLine, sinkShutoffValve, sinkRecommendations } = data;
    const {
      toiletSupplyLine,
      toiletShutoffCount,
      toiletShutoffValve,
      toiletWaterFillDevice,
      toiletSeal,
      toiletDyeTest,
      toiletRecommendations,
    } = data;
    const {
      washingMachineSupplyLine,
      washingMachineShutoffValve,
      washingMachineSupplyLineCount,
      washingMachineRecommendations,
    } = data;
    const {
      dishwasherSupplyLine,
      iceMakerSupplyLine,
      otherWaterRecommendations,
    } = data;
    const {
      treatmentSystems,
      treatmentRecommendations,
      treatmentTypeAndLeaks,
    } = data;
    const {
      sewageDischargePiping,
      sewagePitAndPump,
      sewageRecommendations,
      sewageTank,
    } = data;
    const {
      exteriorFaucets,
      exteriorLinesAndProtection,
      exteriorRecommendations,
    } = data;
    const {
      smartValveDigging,
      smartValveMainPipeMaterial,
      smartValveMainPipeSize,
      smartValveWifiSignal,
      smartValveElectricianNeeded,
      smartValveOutletWithinReach,
      smartValveInstallationType,
      smartValveLocation,
      smartValveRecommendations,
      smartValveWaterLineDepth,
      smartValveUtilitiesMarked,
      smartValveSpecialTools,
      smartValvePrvReplace,
    } = data;
    return JsonHttp.post({
      url: `${getUrl()}/webhooks/wsa`,
      body: {
        // The new webhook
        new: true,
        email,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        phone_number: phoneNumber.trim(),
        street: address1.trim(),
        street_line_2: address2.trim(),
        city: city.trim(),
        zip_code: zipCode.trim(),
        water_pressure_picture_urls: pressureGaugeUrls,
        pressure_reducing_valve_size: pressureReducingValveSize.trim(),
        pressure_reducing_valve_working_condition: asString(
          pressureReducingValveWorkingCondition
        ),
        main_shut_off_valve_size: mainShutoffValveSize.trim(),
        main_shut_off_valve_working_condition: asString(
          mainShutoffValveWorkingCondition
        ),
        water_heater_type: waterHeaterType.trim(),
        water_heater_location_picture_urls: tankLocationUrls,
        water_heater_rating_plate_picture_urls: ratingPlateUrls,
        water_heater_gallons: waterHeaterGallons.trim(),
        temperature_discharge_piping_acceptable: asString(
          dischargePipingAcceptable
        ),
        pan_drain_present: asString(panAndDrainPresent),
        water_heater_expansion_tank: asString(waterHeaterExpansionTank),
        water_heater_expansion_tank_tested: asString(
          waterHeaterExpansionTankTested
        ),
        circulation_system: asString(circulationSystemPresent),
        pressure_reducing_valve_recommendation:
          pressureReducingRecommendations.trim(),
        main_shut_off_valve_recommendation: mainShutoffRecommendations.trim(),
        water_heater_recommendation: waterHeaterRecommendations.trim(),
        water_source: interiorWaterSource.trim(),
        main_water_piping_type: interiorMainWaterPipingType,
        visible_pipes_risk_freezing: asString(
          interiorVisiblePipesRiskOfFreezing
        ),
        water_pipe_recommendations: interiorRecommendations.trim(),
        sink_water_supply_lines: sinkSupplyLine.trim(),
        sink_shut_off_valves: asString(sinkShutoffValve),
        sink_recommendations: sinkRecommendations.trim(),
        toilet_water_supply_lines: toiletSupplyLine.trim(),
        toilet_shut_off_valves: `${asString(
          toiletShutoffValve
        )}: ${toiletShutoffCount.trim()}`,
        toilet_water_fill_device: asString(toiletWaterFillDevice),
        toilet_seal: asString(toiletSeal),
        toilet_dye_test: asString(toiletDyeTest),
        toilet_recommendations: toiletRecommendations.trim(),
        washing_machine_water_supply_lines: `${washingMachineSupplyLineCount.trim()} ${washingMachineSupplyLine.trim()}`,
        washing_machine_shut_off_valves: asString(washingMachineShutoffValve),
        washing_machine_recommendations: washingMachineRecommendations.trim(),
        other_dishwasher_water_supply_lines: dishwasherSupplyLine.trim(),
        other_icemaker_water_supply_lines: iceMakerSupplyLine.trim(),
        other_machine_recommendations: otherWaterRecommendations.trim(),
        water_treatment_type_visible_leaks: treatmentTypeAndLeaks.trim(),
        water_treatment_systems: treatmentSystems.trim(),
        water_treatment_recommendations: treatmentRecommendations.trim(),
        sewage_tank: asString(sewageTank),
        sewage_sump_pump: sewageDischargePiping.trim(),
        sewage_ejector_pump: sewagePitAndPump.trim(),
        sewage_recommendations: sewageRecommendations.trim(),
        exterior_hose_faucets: exteriorFaucets.trim(),
        exterior_fountains_pool_pond_fill: exteriorLinesAndProtection.trim(),
        exterior_recommendations: exteriorRecommendations.trim(),
        smart_valve_location: smartValveLocation.trim(),
        smart_valve_outlet_within_reach: asString(smartValveOutletWithinReach),
        smart_valve_electrician_needed: asString(smartValveElectricianNeeded),
        smart_valve_wifi_available: asString(smartValveWifiSignal),
        smart_valve_install_type: smartValveInstallationType.trim(),
        smart_valve_main_pipe_size: smartValveMainPipeSize.trim(),
        smart_valve_main_pipe_material: smartValveMainPipeMaterial.trim(),
        smart_valve_digging: asString(smartValveDigging),
        smart_valve_water_line_depth: smartValveWaterLineDepth.trim(),
        smart_valve_utilities_marked: asString(smartValveUtilitiesMarked),
        smart_valve_special_tools: smartValveSpecialTools.trim(),
        smart_valve_prv_replacement: asString(smartValvePrvReplace),
        smart_valve_recommendations: smartValveRecommendations.trim(),
      },
    });
  }
}
