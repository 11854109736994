import React from "react";
import { SubmitState } from "../wsa/model/SubmitState";

/**
 * Context that provides the current singleton
 */
export const SubmitStateContext = React.createContext<SubmitState | undefined>(
  undefined
);

/**
 * Custom hook for retrieving SubmitState
 */
export function useSubmitState(): SubmitState {
  const state = React.useContext(SubmitStateContext);
  if (!state) {
    throw new Error("Missing SubmitStateContext.Provider");
  }
  return state;
}
