import { NavigationProps, WsaProps } from "../wsa/WsaViewModel";
import { Box } from "@mui/material";
import React from "react";
import { MARGIN_DEFAULT } from "../util/constant/constants";
import { ImageUpload } from "../ui/ImageUpload";
import { Label } from "../ui/Label";
import { Dropdown } from "../ui/Dropdown";
import { WaterPressurePayload } from "../model/WaterPressurePayload";
import { Attachment } from "../model/Attachment";
import { YesNo } from "../ui/BinaryChoice";
import { TriState } from "../model/TriState";
import { simpleDropdownItem } from "../ui/dropdown/DropdownItem";
import {
  FastTextInput,
  MUTATE_NUMBER_ONLY,
  NUMBER_INPUT,
} from "../ui/TextInput";
import { isProvided, RequiredField } from "../ui/RequiredField";
import { OptionalSection } from "../ui/OptionalSection";
import { Navigation } from "../ui/Navigation";
import { FeatureFlags } from "../util/constant/flags";

const WATER_HEATER_TYPES = [
  simpleDropdownItem(`Electric`),
  simpleDropdownItem(`Gas`),
  simpleDropdownItem(`Tankless`),
  simpleDropdownItem(`Other`),
];

const PRESSURE_REDUCING_VALVE_SIZES = [
  simpleDropdownItem(`3/4"`),
  simpleDropdownItem(`1"`),
  simpleDropdownItem(`1-1/4"`),
  simpleDropdownItem(`1-1/2"`),
];

const MAIN_SHUTOFF_VALVE_SIZES = [
  simpleDropdownItem(`3/4"`),
  simpleDropdownItem(`1"`),
  simpleDropdownItem(`1-1/4"`),
  simpleDropdownItem(`1-1/2"`),
  simpleDropdownItem(`2"`),
];

interface Props
  extends Pick<WsaProps, keyof WaterPressurePayload | "onWaterPressureSubmit">,
    NavigationProps {}

interface State extends WaterPressurePayload {
  showErrors: boolean;
}

export class WaterPressureSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { onPreviousSection, onWaterPressureSubmit, ...rest } = props;
    this.state = { ...rest, showErrors: false };
  }

  canSubmit = () => {
    if (FeatureFlags.DONT_ENFORCE_REQUIRED) {
      return true;
    }

    const {
      waterPressureGaugePictures,
      waterHeaterExpansionTankTested,
      pressureReducingValveWorkingCondition,
      pressureReducingValveSize,
      mainShutoffValveWorkingCondition,
      mainShutoffValveSize,
      waterHeaterType,
      waterHeaterRatingPlatePictures,
      waterHeaterTankLocationPictures,
      waterHeaterGallons,
      panAndDrainPresent,
      dischargePipingAcceptable,
      waterHeaterExpansionTank,
      circulationSystemPresent,
    } = this.state;

    if (!isProvided(waterHeaterType)) {
      return false;
    }
    if (!isProvided(waterHeaterRatingPlatePictures)) {
      return false;
    }
    if (!isProvided(waterHeaterTankLocationPictures)) {
      return false;
    }
    if (!isProvided(waterHeaterGallons)) {
      return false;
    }
    if (!isProvided(panAndDrainPresent)) {
      return false;
    }
    if (!isProvided(dischargePipingAcceptable)) {
      return false;
    }

    if (!isProvided(waterHeaterExpansionTank)) {
      return false;
    }

    if (!isProvided(circulationSystemPresent)) {
      return false;
    }

    if (!isProvided(waterPressureGaugePictures)) {
      return false;
    }

    if (!isProvided(pressureReducingValveSize)) {
      return false;
    }

    if (!isProvided(pressureReducingValveWorkingCondition)) {
      return false;
    }

    if (!isProvided(mainShutoffValveSize)) {
      return false;
    }

    if (!isProvided(mainShutoffValveWorkingCondition)) {
      return false;
    }

    if (this.isShowExpansionTankSection()) {
      if (!isProvided(waterHeaterExpansionTankTested)) {
        return false;
      }
    }

    return true;
  };

  handleSubmit = () => {
    if (this.canSubmit()) {
      const { onWaterPressureSubmit } = this.props;
      const { showErrors, ...rest } = this.state;
      onWaterPressureSubmit(rest);
    } else {
      this.setState({ showErrors: true });
    }
  };

  handleWaterPressureGaugePicturesChanged = (attachments: Attachment[]) => {
    this.setState({ waterPressureGaugePictures: attachments });
  };

  handlePressureReducingValveSizeChanged = (value: string) => {
    this.setState({ pressureReducingValveSize: value });
  };

  handleMainShutoffValveSizeChanged = (value: string) => {
    this.setState({ mainShutoffValveSize: value });
  };

  handleWorkingConditionPressureReducingValveChanged = (working: TriState) => {
    this.setState({
      pressureReducingValveWorkingCondition: working,
    });
  };

  handleWorkingConditionMainShutoffChanged = (working: TriState) => {
    this.setState({
      mainShutoffValveWorkingCondition: working,
    });
  };

  handleTypeChanged = (type: string) => {
    this.setState({
      waterHeaterType: type,
    });
  };

  handleWaterHeaterRatingPlateChanged = (attachments: Attachment[]) => {
    this.setState({ waterHeaterRatingPlatePictures: attachments });
  };

  handleWaterHeaterLocationChanged = (attachments: Attachment[]) => {
    this.setState({ waterHeaterTankLocationPictures: attachments });
  };

  handleWaterHeaterGallonsChanged = (gallons: string) => {
    this.setState({ waterHeaterGallons: gallons });
  };

  handleDischargePipingChanged = (working: TriState) => {
    this.setState({ dischargePipingAcceptable: working });
  };

  handlePanAndDrainChanged = (working: TriState) => {
    this.setState({ panAndDrainPresent: working });
  };

  handleWaterHeaterExpansionTank = (working: TriState) => {
    this.setState({ waterHeaterExpansionTank: working });
  };

  handleWaterHeaterExpansionTankTested = (working: TriState) => {
    this.setState({ waterHeaterExpansionTankTested: working });
  };

  handleCirculation = (working: TriState) => {
    this.setState({ circulationSystemPresent: working });
  };

  handlePressureRecommendations = (value: string) => {
    this.setState({ pressureReducingRecommendations: value });
  };

  handleMainShutOffRecommendations = (value: string) => {
    this.setState({ mainShutoffRecommendations: value });
  };

  handleWaterHeaterRecommendations = (value: string) => {
    this.setState({ waterHeaterRecommendations: value });
  };

  isShowExpansionTankSection = () => {
    const { waterHeaterExpansionTank } = this.state;
    return waterHeaterExpansionTank === TriState.TRUE;
  };

  render() {
    const { onPreviousSection } = this.props;
    const {
      pressureReducingValveWorkingCondition,
      pressureReducingValveSize,
      waterPressureGaugePictures,
      mainShutoffValveSize,
      mainShutoffValveWorkingCondition,
      waterHeaterExpansionTank,
      waterHeaterExpansionTankTested,
      waterHeaterGallons,
      waterHeaterRatingPlatePictures,
      waterHeaterRecommendations,
      waterHeaterTankLocationPictures,
      waterHeaterType,
      circulationSystemPresent,
      panAndDrainPresent,
      dischargePipingAcceptable,
      showErrors,
      mainShutoffRecommendations,
      pressureReducingRecommendations
    } = this.state;
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Picture of Water Pressure Gauge" />
          <ImageUpload
            attachments={waterPressureGaugePictures}
            onCommitAttachments={this.handleWaterPressureGaugePicturesChanged}
          />
          <RequiredField show={showErrors} value={waterPressureGaugePictures} />
        </Box>
        <Box display="flex" flexDirection="column" my={MARGIN_DEFAULT}>
          <Dropdown
            label="Pressure Reducing Valve Size"
            value={pressureReducingValveSize}
            onChange={this.handlePressureReducingValveSizeChanged}
            items={PRESSURE_REDUCING_VALVE_SIZES}
          />
          <RequiredField show={showErrors} value={pressureReducingValveSize} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Working Condition? (Pressure Reducing Valve)" />
          <YesNo
            value={pressureReducingValveWorkingCondition}
            onChange={this.handleWorkingConditionPressureReducingValveChanged}
          />
          <RequiredField
            show={showErrors}
            value={pressureReducingValveWorkingCondition}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Pressure Reducing Valve Recommendations?"
            value={pressureReducingRecommendations}
            onChange={this.handlePressureRecommendations}
            multiline={true}
            maxRows={6}
          />
        </Box>
        <Box display="flex" flexDirection="column" my={MARGIN_DEFAULT}>
          <Dropdown
            label="Main Shutoff Valve Size"
            value={mainShutoffValveSize}
            onChange={this.handleMainShutoffValveSizeChanged}
            items={MAIN_SHUTOFF_VALVE_SIZES}
          />
          <RequiredField show={showErrors} value={mainShutoffValveSize} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Working Condition? (Main Shutoff Valve)" />
          <YesNo
            value={mainShutoffValveWorkingCondition}
            onChange={this.handleWorkingConditionMainShutoffChanged}
          />
          <RequiredField
            show={showErrors}
            value={mainShutoffValveWorkingCondition}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Main Shut Off Valve Recommendations?"
            value={mainShutoffRecommendations}
            onChange={this.handleMainShutOffRecommendations}
            multiline={true}
            maxRows={6}
          />
        </Box>
        <Box display="flex" flexDirection="column" my={MARGIN_DEFAULT}>
          <Dropdown
            label="Water Heater Type"
            value={waterHeaterType}
            onChange={this.handleTypeChanged}
            items={WATER_HEATER_TYPES}
          />
          <RequiredField show={showErrors} value={waterHeaterType} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Picture of Rating Plate" />
          <ImageUpload
            attachments={waterHeaterRatingPlatePictures}
            onCommitAttachments={this.handleWaterHeaterRatingPlateChanged}
          />
          <RequiredField
            show={showErrors}
            value={waterHeaterRatingPlatePictures}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Picture of Location of Tank" />
          <ImageUpload
            attachments={waterHeaterTankLocationPictures}
            onCommitAttachments={this.handleWaterHeaterLocationChanged}
          />
          <RequiredField
            show={showErrors}
            value={waterHeaterTankLocationPictures}
          />
        </Box>

        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Water Heater Gallons (Tank Type Only)"
            value={waterHeaterGallons}
            onChange={this.handleWaterHeaterGallonsChanged}
            type="tel"
            inputProps={NUMBER_INPUT}
            mutate={MUTATE_NUMBER_ONLY}
          />
          <RequiredField show={showErrors} value={waterHeaterGallons} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Temperature & Pressure Relief Valve - Discharge Piping Acceptable?" />
          <YesNo
            value={dischargePipingAcceptable}
            onChange={this.handleDischargePipingChanged}
          />
          <RequiredField show={showErrors} value={dischargePipingAcceptable} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Pan and Drain Present?" />
          <YesNo
            value={panAndDrainPresent}
            onChange={this.handlePanAndDrainChanged}
          />
          <RequiredField show={showErrors} value={panAndDrainPresent} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Water Heater Expansion Tank?" />
          <YesNo
            value={waterHeaterExpansionTank}
            onChange={this.handleWaterHeaterExpansionTank}
          />
          <RequiredField show={showErrors} value={waterHeaterExpansionTank} />
        </Box>
        <OptionalSection show={this.isShowExpansionTankSection()}>
          <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
            <Label name="Expansion Tank Tested for Proper Air Pressure" />
            <YesNo
              value={waterHeaterExpansionTankTested}
              onChange={this.handleWaterHeaterExpansionTankTested}
            />
            <RequiredField
              show={showErrors}
              value={waterHeaterExpansionTankTested}
            />
          </Box>
        </OptionalSection>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Label name="Circulation System Present?" />
          <YesNo
            value={circulationSystemPresent}
            onChange={this.handleCirculation}
          />
          <RequiredField show={showErrors} value={circulationSystemPresent} />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Water Heater Recommendations?"
            value={waterHeaterRecommendations}
            onChange={this.handleWaterHeaterRecommendations}
            multiline={true}
            maxRows={6}
          />
        </Box>

        <Box display="flex" mb={MARGIN_DEFAULT}>
          <Navigation
            onNext={this.handleSubmit}
            onPrevious={onPreviousSection}
          />
        </Box>
      </Box>
    );
  }
}
