import { NavigationProps, WsaProps } from "../wsa/WsaViewModel";
import React from "react";
import { Box } from "@mui/material";
import { MARGIN_DEFAULT } from "../util/constant/constants";
import { Navigation } from "../ui/Navigation";
import { Dropdown } from "../ui/Dropdown";
import { simpleDropdownItem } from "../ui/dropdown/DropdownItem";
import { FastTextInput } from "../ui/TextInput";
import { OtherWaterPayload } from "../model/OtherWaterPayload";

interface Props
  extends Pick<WsaProps, keyof OtherWaterPayload | "onOtherWaterSubmit">,
    NavigationProps {}

interface State extends OtherWaterPayload {}

const ICE_MAKER_SUPPLY_LINES = [
  simpleDropdownItem(`Plastic`),
  simpleDropdownItem(`Copper`),
  simpleDropdownItem(`Stainless Braided`),
  simpleDropdownItem(`Other`),
];

const DISHWASHER_SUPPLY_LINES = [
  simpleDropdownItem(`Plastic`),
  simpleDropdownItem(`Metal`),
  simpleDropdownItem(`Stainless Braided`),
  simpleDropdownItem(`Other`),
];

export class OtherWaterSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { onOtherWaterSubmit, onPreviousSection, ...rest } = props;
    this.state = {
      ...rest,
    };
  }

  handleDishwasherSupplyLines = (value: string) => {
    this.setState({ dishwasherSupplyLine: value });
  };

  handleIceMakerSupplyLines = (value: string) => {
    this.setState({ iceMakerSupplyLine: value });
  };

  handleRecommendations = (value: string) => {
    this.setState({ otherWaterRecommendations: value });
  };

  handleSubmit = () => {
    const { ...rest } = this.state;
    const { onOtherWaterSubmit } = this.props;
    onOtherWaterSubmit(rest);
  };

  render() {
    const { onPreviousSection } = this.props;
    const {
      dishwasherSupplyLine,
      iceMakerSupplyLine,
      otherWaterRecommendations,
    } = this.state;
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Dropdown
            label="Water Supply Lines to Dishwasher?"
            value={dishwasherSupplyLine}
            onChange={this.handleDishwasherSupplyLines}
            items={DISHWASHER_SUPPLY_LINES}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Dropdown
            label="Water Supply Lines to Ice Maker?"
            value={iceMakerSupplyLine}
            onChange={this.handleIceMakerSupplyLines}
            items={ICE_MAKER_SUPPLY_LINES}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Other Water Supply Recommendations?"
            value={otherWaterRecommendations}
            onChange={this.handleRecommendations}
            multiline={true}
            maxRows={6}
          />
        </Box>
        <Box display="flex" mb={MARGIN_DEFAULT}>
          <Navigation
            onNext={this.handleSubmit}
            onPrevious={onPreviousSection}
          />
        </Box>
      </Box>
    );
  }
}
