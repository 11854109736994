import { createTheme, responsiveFontSizes } from "@mui/material";

export const POODLE_THEME_COLOR_PRIMARY = {
  light: "rgb(53, 135, 195)",
  main: "rgb(41, 93, 255)",
  dark: "rgb(10, 22, 83)",
};

export const POODLE_THEME_COLOR_NEUTRAL = {
  light: "#607d8b",
  main: "#455a64",
  dark: "#263238",
};

export const POODLE_THEME_COLOR_BLACK = {
  light: "#000",
  main: "#000",
  dark: "#000",
};

const theme = createTheme({
  palette: {
    primary: POODLE_THEME_COLOR_PRIMARY,
    neutral: POODLE_THEME_COLOR_NEUTRAL,
    black: POODLE_THEME_COLOR_BLACK,
  },
  spacing: 8,
  typography: {
    // Don't enforce uppercase on buttons
    button: {
      textTransform: "none",
    },
  },
});

export const WsaTheme = responsiveFontSizes(theme);
