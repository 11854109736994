export interface DropdownItem {
  name: string;
  value: string;
}

export function simpleDropdownItem(name: string): DropdownItem {
  return {
    name,
    value: name,
  };
}
