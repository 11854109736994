import { NavigationProps, WsaProps } from "../wsa/WsaViewModel";
import React from "react";
import { Box } from "@mui/material";
import { MARGIN_DEFAULT } from "../util/constant/constants";
import { Navigation } from "../ui/Navigation";
import { Dropdown } from "../ui/Dropdown";
import { simpleDropdownItem } from "../ui/dropdown/DropdownItem";
import { FastTextInput } from "../ui/TextInput";
import { ExteriorPayload } from "../model/ExteriorPayload";

interface Props
  extends Pick<WsaProps, keyof ExteriorPayload | "onExteriorSubmit">,
    NavigationProps {}

interface State extends ExteriorPayload {}

const FAUCETS = [
  simpleDropdownItem(`Vacuum Breaker Present`),
  simpleDropdownItem(`Frost Free`),
  simpleDropdownItem(`Freezing Possible`),
  simpleDropdownItem(`Other`),
];

export class ExteriorSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { onExteriorSubmit, onPreviousSection, ...rest } = props;
    this.state = {
      ...rest,
    };
  }

  handleLines = (value: string) => {
    this.setState({ exteriorLinesAndProtection: value });
  };

  handleFaucets = (value: string) => {
    this.setState({ exteriorFaucets: value });
  };

  handleRecommendations = (value: string) => {
    this.setState({ exteriorRecommendations: value });
  };

  handleSubmit = () => {
    const { ...rest } = this.state;
    const { onExteriorSubmit } = this.props;
    onExteriorSubmit(rest);
  };

  render() {
    const { onPreviousSection } = this.props;
    const {
      exteriorRecommendations,
      exteriorFaucets,
      exteriorLinesAndProtection,
    } = this.state;
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <Dropdown
            label="Exterior Hose Faucets?"
            value={exteriorFaucets}
            onChange={this.handleFaucets}
            items={FAUCETS}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Fountains, Pool/Pond Fill Lines: Type of Line and Freeze Protection"
            value={exteriorLinesAndProtection}
            onChange={this.handleLines}
            multiline={true}
            maxRows={6}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={MARGIN_DEFAULT}>
          <FastTextInput
            label="Exterior Water Supply Recommendations?"
            value={exteriorRecommendations}
            onChange={this.handleRecommendations}
            multiline={true}
            maxRows={6}
          />
        </Box>
        <Box display="flex" mb={MARGIN_DEFAULT}>
          <Navigation
            onNext={this.handleSubmit}
            onPrevious={onPreviousSection}
          />
        </Box>
      </Box>
    );
  }
}
