import { IS_DEBUG_MODE } from "../constant/constants";

export class Logger {
  static log(...args: any[]) {
    if (IS_DEBUG_MODE) {
      console.log(...args);
    }
  }

  static warn(...args: any[]) {
    if (IS_DEBUG_MODE) {
      console.warn(...args);
    }
  }

  static error(e: Error, ...args: any[]) {
    if (IS_DEBUG_MODE) {
      console.error(e, ...args);
    }
  }
}
